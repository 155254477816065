import React, { useCallback, useEffect, useState, useRef } from "react";
import { MapContainer, Marker, Polygon, Polyline, Tooltip } from "react-leaflet";
import L, { marker } from "leaflet";
import { useMap, GeoJSON, useMapEvents } from "react-leaflet";
import * as turf from "@turf/turf";
import Loader from "../shared/Loader";
import localStorageService from "../../utils/localStorageService";
import { useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import VirtualBorehole from "./planningTab/VirtualBorehole";
import GeoCutSection from "./planningTab/GeoCutSection";
import PolygonBhExtract from "./dataTab/PolygonBhExtract";
import 'leaflet/dist/leaflet.css'
import MapDetailsModal from "./mapTab/mapDetailsModal";
import { Modal } from "reactstrap";
import httpClientPy from "../../utils/httpClientPy";
import MapMarkerLabel from "./mapTab/mapMarkerLabel";

import { faLayerGroup, faCaretLeft, faExpand, faArrowUpRightFromSquare, faSatellite, faBook, faFileExcel} from "@fortawesome/free-solid-svg-icons";
library.add(faLayerGroup, faCaretLeft, faExpand, faArrowUpRightFromSquare, faSatellite, faBook);


const MapData = (props) => {
  //modal controls the display of the borehole preview modal
  //data is used to store the map marker data called from the backend
  const [data, setData] = useState([]);
  //selecteddataitem is used to hold the data of a borehole record which is selected on the map and provides additional detail in the modal
  const [selectedDataItem, setSelectedDataItem] = useState(null);

  //clickLocation is used to record where is being clicked on the map and pass it into virtualborehole component for checking there are digitised borehole records in the area
  const [clickLocation, setClickLocation] = useState({
    lat: 1,
    lng: 1,
  });

  const [boreholeSelected, setBoreholeSelected] = useState(false)

  const [recordLocation, setRecordLocation] = useState()
  const [readyMarkers, setReadyMarkers] = useState()
  // variable to store current user email address
  const [domain, setDomain] = useState("");

  const zoom = useRef([]);
  const renders = useRef(1);


  var markers = [];

  useEffect(() => {
    httpClientPy.get(`/user`).then((response) => {
        let userList = response.data
        let domain = userList.email.split('@')[1]
        setDomain(domain)
    })
  }, []);
  

  // listen for cursor events on map and update state
  const map = useMapEvents({
    click() {
      if((props.addBorehole || props.updateBorehole) && !props.searchClicked ){
        props.onFixBorehole(true)
      }
    },
    // contextmenu: (e) => {
    //   props.onTrackCursor(e.latlng.lat, e.latlng.lng)
    // },
    mousemove(e) {
      // setState your coords here
      // coords exist in "e.latlng.lat" and "e.latlng.lng"
      if (!props.fixVirtualBorehole && props.virtualBorehole){
        setClickLocation({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        })
      }
      
      if(props.addBorehole || props.updateBorehole){
        props.onTrackCursor(e.latlng.lat, e.latlng.lng)
      }
    },
  })

  // freeze map if virtual borehole is dropped
  const mapholder = useMap();
  
  if( props.fixVirtualBorehole){
    mapholder.dragging.disable();
    mapholder.doubleClickZoom.disable();
    mapholder.touchZoom.disable();
    mapholder.scrollWheelZoom.disable();
  } else{
    mapholder.dragging.enable()
    mapholder.doubleClickZoom.enable()
    mapholder.touchZoom.enable()
    mapholder.scrollWheelZoom.enable()
  }

  const location = useLocation();
  const { state } = location;

  var mapStateFromRoute = null
    
  if(state != null && state.geo_record != null && state.geo_record.position.lat !== 0) {
    mapStateFromRoute = { 
      location: [
        parseFloat(state.geo_record.position.lat),
        parseFloat(state.geo_record.position.lng),
      ],
      zoom: 18,
    }
  }
  else{
    mapStateFromRoute = null
  }
  
  const updateMap = () => {
    const b = map.getBounds();
    const z = map.getZoom();
    fetchMapData({
      bounds: {
        northEast: b.getNorthEast(),
        northWest: b.getNorthWest(),
        southWest: b.getSouthWest(),
        southEast: b.getSouthEast()
      },
      zoom: z,
      project_id: props.projectDetails ? props.projectDetails.id : -1,
    })
    .then((response) => {
      markers = response.features.filter((obj) => {
        return !obj.properties.is_cluster;
      });

      let total_ready = 0
      
      let readyMarkersHolder = []
      for (let singleMarker of markers){
        if (singleMarker.properties.status==='ready'){
          readyMarkersHolder.push(singleMarker)
          total_ready += 1
        }
      }
      
      zoom.current = map.getZoom();
      setReadyMarkers(readyMarkersHolder)
      props.onUpdateZoomMarkers([zoom.current, total_ready]);
      setData(response);

    })
    .catch((error) => {
      console.error(error);
    });
  };

  // This function check if there is any project detail sent from the project page and if there is, it will fly to the project location
  useEffect(() => {

    let lat = 0;
    let lng = 0;
    let zoomPolygon = 15;

    let polygon_object = getPolygonCoordinates(props.projectDetails.polygon);
    let distance = haversineDistance(polygon_object.minLat, polygon_object.minLng, polygon_object.maxLat, polygon_object.maxLng,)
    
    // try and get localStorageService.getItem("map_state", newState);
    if (localStorageService.getItem("map_state")) {
      const newState = localStorageService.getItem("map_state");
      lat = newState.location[0];
      lng = newState.location[1];
      zoomPolygon = newState.zoom;
    } else if (props.projectDetails && props.geoRecord === null) {
      lat = polygon_object.lat;
      lng = polygon_object.lng;
      zoomPolygon = calculateZoomLevel(distance);
    } else if (props.geoRecord !== null) {
      if(props.geoRecord.position.lat !== 0 && props.geoRecord.position.lng !== 0) {
        lat = parseFloat(props.geoRecord.position.lat);
        lng = parseFloat(props.geoRecord.position.lng);
        zoomPolygon = 18;
      } else {
        lat = polygon_object.lat;
        lng = polygon_object.lng;
        zoomPolygon = calculateZoomLevel(distance);
      }
    } 

    if (props.cursorLat !== '') {
      lat = props.cursorLat;
      lng = props.cursorLng;
    }

    //wait 300 miliseconds before setting the map view to the project location
    setTimeout(() => {

      map.setView(new L.LatLng(lat, lng), zoomPolygon);
      updateMap();

      if((map && map !== null) && (!props.addProject || !props.updateProjectLocation)){
        let timeoutId = null;  // Initialize a variable to store the timeout ID
        const onIdle = () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          
          // Set a new timer
          timeoutId = setTimeout(() => {
            updateMap();
          }, 300); // Wait for 300 milliseconds
        };
      
        map.on("load moveend", onIdle);

        return () => {
          map.off("load moveend", onIdle);
        };
      }


    }, 300);

    const newState = {
      location: [lat, lng],
      zoom: 18,
    };
    localStorageService.setItem("map_state", newState);

    
  }, [props.projectDetails, props.geoRecord]);



  // highlight selected borehole functions


  useEffect(() => {
    let timeoutId;

    if (props.searchGeoRecord !== undefined || mapStateFromRoute !== null){
      if (props.searchGeoRecord || mapStateFromRoute !== null){               
        if(mapStateFromRoute === null || (props.searchGeoRecord && props.searchGeoRecord.location[0]==null && props.searchGeoRecord.location[1]==null)){
          updateMap();
        }
        else{
          var newState = null
          if(mapStateFromRoute !== null){
            newState = mapStateFromRoute
          }
          else{
            newState = {
              location: [parseFloat(props.searchGeoRecord.location[0]), parseFloat(props.searchGeoRecord.location[1])],
              zoom: 18,
            };
          }

          map.setView(new L.LatLng(parseFloat(newState.location[0]), parseFloat(newState.location[1])), newState.zoom);

          localStorageService.setItem("map_state", newState);
          setBoreholeSelected(newState)
          timeoutId = setTimeout(() => {
            setBoreholeSelected(false);
          }, 5000);
        }
      }
      else{
        setBoreholeSelected(false)
      } 
    }
    else{
      setBoreholeSelected(false)
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.searchGeoRecord]);

      
  const toggle = () => {
    if(selectedDataItem){
      setSelectedDataItem(null);
      setBoreholeSelected(false)
    }
  }

  renders.current = parseFloat(renders.current) + 1;

  // initialise the polygons and icons for the map
  if (data && data.features) {
    
    const polygons = data.features.filter((obj) => {
      return obj.properties.is_cluster;
    });

    markers = data.features.filter((obj) => {
      return !obj.properties.is_cluster;
    });


    return (
      <>
        {!selectedDataItem ? (
          <></>
        ) : (
          <Modal id="detailsModal" className="fullscreen_modal" centered={true} fullscreen={true} isOpen={selectedDataItem!==null} toggle={()=>toggle()}>
            <MapDetailsModal selectedDataItem={selectedDataItem} recordLocation={recordLocation} updateMap={()=>{updateMap();}} toggleModal={()=>toggle()} 
                             onToggleDetails={()=>setSelectedDataItem()} projectDetails={props.projectDetails}
                             setGeoRecordPosition = {(position) => props.setGeoRecordPosition(position)}
                             toggleUpdateBorehole = {(status) => props.toggleUpdateBorehole(status)}
                             boreHoleInfoUpdate={(status)=>{updateMap();}}
                             setGeoRecord = {(record) => props.setGeoRecord(record)}></MapDetailsModal>
          </Modal>
        )}
        {polygons.map((feature) => {
          const { index, label } = feature.properties;
          const centroid = turf.centroid(feature);
          return (
            <GeoJSON
              key={`polygon-${index}`}
              label={label}
              data={feature}
              pathOptions={{
                color: "#888",
                opacity: 0.2,
                fillColor: "transparent",
                fillOpacity: 0,
                weight: 2,
              }}
              eventHandlers={{
                click: (e) => {
                    if (!props.virtualBorehole && !props.addBorehole && !props.updateBorehole){
                    const expansionZoom = Math.min(20, map.getZoom() + 1);
                    map.setView(
                      [
                        centroid.geometry.coordinates[1],
                        centroid.geometry.coordinates[0],
                      ],
                      expansionZoom,
                      {
                        animate: true,
                      }
                    );
                  }
                }
              }}
            >
              <Tooltip sticky direction="auto" className="polygon-tooltip">
                {label}
              </Tooltip>
            </GeoJSON>
          );
        })}
        {markers.map((feature) => {
          const longitude = feature.properties.coordinates.lng;
          const latitude = feature.properties.coordinates.lat;
          const { id, ownership, name } = feature.properties;
          return (
            <React.Fragment key={`marker-fragment-${id}`}>
              <Marker
                key={`marker-${id}`}
                position={[latitude, longitude]}
                icon={
                  props.mapLayer === 'satellite' ? (
                    ownership === 'public'
                      ? boreholeWhite
                      : ownership === 'private'
                      ? boreholeRed
                      : boreholeWhiteSmall
                  ) : (
                    ownership === 'public'
                      ? boreholeBlack
                      : ownership === 'private'
                      ? boreholeRed
                      : boreholeGrey
                  )
                }
                eventHandlers={{
                  click: (e) => {
                    if (!props.virtualBorehole && !props.addBorehole && !props.updateBorehole) {
                      if (boreholeSelected) {
                        setBoreholeSelected(false);
                      }
                      zoom.current = map.getZoom();
                      if (zoom.current < 18) {
                        map.setView([latitude, longitude], 18, {
                          animate: true,
                        });
                      }
                      setSelectedDataItem(id);
                      setRecordLocation([latitude, longitude]);
                    }
                  },
                }}
              >
              </Marker>
              <MapMarkerLabel zoom={zoom} latitude={latitude} longitude={longitude} markerText={name} />
              
              {boreholeSelected &&
                <Marker
                  key={`marker-${id}-selected`}
                  position={[boreholeSelected.location[0], boreholeSelected.location[1]]}
                  icon={selectedBorehole}
                  eventHandlers={{
                    click: (e) => {
                      if (!props.virtualBorehole && !props.addBorehole && !props.updateBorehole){
                        if (boreholeSelected){
                          setBoreholeSelected(false)
                        }
                        zoom.current = map.getZoom();
                        if (zoom.current < 18) {
                          map.setView([latitude, longitude], 18, {
                            animate: true,
                          });
                        }
                        setSelectedDataItem(boreholeSelected.id)
                        setRecordLocation([latitude, longitude])
                      }
                    }
                  }}
                />  
              }
            </React.Fragment>
          );
        })}
        {props.projectDetails && (
          <>
            <Polyline
              pathOptions={{
                color: 'black',
                fill: props.projectDetails.project_type === 0 ? true : false,
                fillOpacity: 0.05,
                weight: props.projectDetails.project_type === 0 ? 2 : 0,
              }}
              positions={props.projectDetails.polygon}
            />
          </>
        )}
        {(props.virtualBorehole || props.addBorehole || props.updateBorehole) &&
          <VirtualBorehole fixBorehole={props.fixBorehole} 
                          addBorehole={props.addBorehole} 
                          updateBorehole={props.updateBorehole} 
                          updateBoreholeList={(boreholeData)=>props.updateBoreholeList(boreholeData)} 
                          createVirtualBorehole={(status)=>{props.createVirtualBorehole(status)}} 
                          zoom={zoom.current} 
                          clickLocation={clickLocation} 
                          readyMarkers={readyMarkers} 
                          fixVirtualBorehole={props.fixVirtualBorehole} 
                          virtualBorehole={props.virtualBorehole} 
                          selectionRadiusSlider = {props.selectionRadiusSlider}/>              
        }
        { (props.geoCutSection && !props.virtualBorehole && !props.addBorehole && !props.updateBorehole)  &&
        <>
          <GeoCutSection
            onToggleSectionCut={(toggle)=>props.onToggleSectionCut(toggle)}
            projectDetail = {props.projectDetails}
            setGeoRecordPosition = {(position) => props.setGeoRecordPosition(position)}
            toggleUpdateBorehole = {(status) => props.toggleUpdateBorehole(status)}
            setGeoRecord = {(record) => props.setGeoRecord(record)}
          />
        </>
        }
        { props.polygonDrawn &&
          <PolygonBhExtract updatePolygonState = {(polyState) => {props.updatePolygonState(polyState); updateMap();}} 
                            polygonState = {props.polygonState}
                            addProject = {props.addProject}
                            updateProjectLocation = {props.updateProjectLocation}
                            projectType = {props.projectType}
                            projectDetail = {props.projectDetails}
                            domain = {domain} />
        }
      </>
    );
  } else {
    return (
      <>
      {!props.mapLoading && (props.addProject || props.updateProjectLocation) &&
      <PolygonBhExtract updatePolygonState = {(polyState) => {props.updatePolygonState(polyState);}} 
                        polygonState = {props.polygonState}
                        addProject = {props.addProject}
                        updateProjectLocation = {props.updateProjectLocation}
                        projectType = {props.projectType}
                        projectDetail = {props.projectDetails}
                        domain = {domain} />
      }
      </>
    );
  }
};

export default MapData;



function getPolygonCoordinates(polygonCoordinates) {
  if (polygonCoordinates.length === 0) {
    return null;
  }

  let centroidX = 0, centroidY = 0;
  let det = 0, tempDet = 0;
  let j = 0;
  let nVertices = polygonCoordinates.length;

  // Initialize bounding box values
  let maxLat = -90, minLat = 90;
  let maxLng = -180, minLng = 180;

  for (let i = 0; i < nVertices; i++) {
    // Update bounding box
    maxLat = Math.max(maxLat, polygonCoordinates[i][0]);
    minLat = Math.min(minLat, polygonCoordinates[i][0]);
    maxLng = Math.max(maxLng, polygonCoordinates[i][1]);
    minLng = Math.min(minLng, polygonCoordinates[i][1]);

    // The last vertex connects to the first
    j = (i + 1) % nVertices;

    // Calculate the determinant
    tempDet = polygonCoordinates[i][0] * polygonCoordinates[j][1] - polygonCoordinates[j][0] * polygonCoordinates[i][1];
    det += tempDet;

    centroidX += (polygonCoordinates[i][0] + polygonCoordinates[j][0]) * tempDet;
    centroidY += (polygonCoordinates[i][1] + polygonCoordinates[j][1]) * tempDet;
  }

  // The total determinant is divided by 2 because the formula for the area of the polygon is 1/2 the determinant
  det = det / 2;

  // Centroid is the sum of x (or y) coordinates times the determinant, divided by 6 times the area
  centroidX = centroidX / (6 * det);
  centroidY = centroidY / (6 * det);

  // Handling negative area for clockwise polygons
  // if (det < 0) {
  //   centroidX = -centroidX;
  //   centroidY = -centroidY;
  //   det = -det;
  // }

  return {
    'lng': centroidY, 
    'lat': centroidX,
    'maxLat': maxLat,
    'minLat': minLat,
    'maxLng': maxLng,
    'minLng': minLng,
  };
}



function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

// Calculate appropriate zoom level based on distance
function calculateZoomLevel(distance) {
  let zoomLevel = Math.floor(16 - Math.log2(distance)); // Adjust the base value (16) as needed
  if(zoomLevel>18){
    zoomLevel = 18
  }
  return zoomLevel;
}


const fetchMapData = ({ bounds, zoom, project_id }) => {
  
  const query =
    zoom && bounds
      ? `projectId=${project_id}&zoom=${zoom}&northEast_lat=${bounds.northEast.lat}&northEast_lng=${bounds.northEast.lng}&northWest_lat=${bounds.northWest.lat}&northWest_lng=${bounds.northWest.lng}&southWest_lat=${bounds.southWest.lat}&southWest_lng=${bounds.southWest.lng}&southEast_lat=${bounds.southEast.lat}&southEast_lng=${bounds.southEast.lng}`
      : "";

  return httpClientPy.get(`/map/index?${query}`).then((response) => {
    return response.data;
  });
};

// load the borehole log icons
const boreholeBlack = new L.Icon({
  iconUrl: "assets/borehole-black.svg",
  iconSize: [32, 32],
});

const boreholeWhite = new L.Icon({
  iconUrl: "assets/borehole-white.svg",
  iconSize: [25, 25],
});

const boreholeWhiteSmall = new L.Icon({
  iconUrl: "assets/borehole-white.svg",
  iconSize: [20, 20],
});

const boreholeRed = new L.Icon({
  iconUrl: "assets/borehole-red.svg",
  iconSize: [32, 32],
});

const boreholeGrey = new L.Icon({
  iconUrl: "assets/borehole-grey.svg",
  iconSize: [24, 24],
});

const selectedBorehole = new L.Icon({
  iconUrl: "assets/selectedBorehole3.svg",
  iconSize: [60, 60],
});