import React, { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import httpClientPy from '../../utils/httpClientPy';
import authenticationHandler from '../../utils/authenticationHandler';

const Uploader = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [customerSupport, setCustomerSupport] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false)



  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
  const navigate = useNavigate();

  useEffect(() => {
    httpClientPy.get(`/user`).then((response) => {
        let userList = response.data
        setUserInfo(userList)
        setIsAdmin(userList.enterprise_admin)
    })
}, [])


const handleLogout = () => {
  authenticationHandler.logout();
  navigate("/log-in");
};


const accountSettings = () => {
// navigate to external website www.civils.ai/account
  navigate("/user/management");
};

const discordCommunity = () => {
  window.open('https://discord.gg/UFaKqhGNmb');
};

const designTools = () => {
  // open in new tab
  window.open('https://civils.ai/free-civil-engineering-design-software', '_blank');
}



const subscriptionSettings = () => {
  // window.open('https://www.civils.ai/create-portal-session') with userInfo.customer as input parameter
  window.open(`https://www.civils.ai/create-portal-session?customer=${userInfo.customer}`);
};


  return (
    <>
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle className="sub-item text-start" tag="div">
          <span><FontAwesomeIcon className="me-2" icon={faEllipsis}/></span>
          <span>Account</span>
      </DropdownToggle>
      <DropdownMenu>
          {isAdmin &&
              <DropdownItem onClick={() => navigate('/partner/admin')}>Admin Panel</DropdownItem>
            }
          <DropdownItem onClick={accountSettings}>Account settings</DropdownItem>
          {userInfo && userInfo.customer &&
              <DropdownItem onClick={subscriptionSettings}>My subscription</DropdownItem>
          }
          <DropdownItem onClick={()=>setCustomerSupport(!customerSupport)}>Contact support</DropdownItem>
          <DropdownItem onClick={designTools}>Free tools <small className="text-secondary ms-1"><FontAwesomeIcon icon={faArrowUpRightFromSquare}/></small></DropdownItem>
          <DropdownItem onClick={discordCommunity}>Discord community</DropdownItem>
          <DropdownItem onClick={handleLogout}>Sign out</DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <Modal isOpen={customerSupport} toggle={()=>setCustomerSupport(!customerSupport)}>
      <ModalHeader>
      <h4 className="title-bold">Contact Support</h4>
      </ModalHeader>
      <ModalBody>
          <div>Any questions or issues please get in contact us at <b>info@civils.ai</b></div>
      </ModalBody>
    </Modal>
    </>
  );
};

export default Uploader;
