import { faTimes, faSpinner,faCircleChevronRight, faX, faXmark, faPlus, faMinus, faExpand, faArrowRight, faArrowLeft, faRobot, faPaperPlane, faWarning, faMagnifyingGlass, faCheck, faCopy, faDownload, faDownLeftAndUpRightToCenter, faWindowMinimize, faWindowMaximize, faThumbsUp, faThumbsDown, faBook, faTable, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlay } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {useNavigate} from "react-router-dom";
import { Card, CardBody, CardHeader, Spinner, Button, FormGroup, Input } from 'reactstrap';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import httpClientPy from "../../../../utils/httpClientPy";
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { marked } from 'marked';
import { readJsonConfigFile } from 'typescript';
import { logEvent } from '../../../shared/Mixpanel';

marked.setOptions({
    gfm: true,
    tables: true,
  });


const SearchAll = (props) => {

    const [messages, setMessages] = useState([]);
    const [generating, setGenerating] = useState(false);
    const [answer, setAnswer] = useState("");
    const [currentFileName, setCurrentFileName] = useState(null);
    const [pageNumberInput, setPageNumberInput] = useState(1);
    const [typingCivilsGPT, setTypingCivilsGPT] = useState(false);
    const [scale, setScale] = useState(1);
    const [allRefMsgId, setAllRefMsgId] = useState([]);

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const zoomPluginInstance = zoomPlugin();

    const [copyStatus, setCopyStatus] = useState(false);

    // const { jumpToPage } = pageNavigationPluginInstance;
    // const { zoomTo } = zoomPluginInstance;
    const [chatInput, setChatInput] = useState('');
    const navigate = useNavigate();

    const handleChangeQuery = (e) => {
        setChatInput(e.target.value);
    }

    const genAiAnswer = (query) => {
        let lastId = 0;
        if (messages.length > 0) {
            lastId = messages[messages.length - 1].id;
        }

        setMessages(prevMessages => [
            ...prevMessages,
            {
                id: lastId + 1,
                user_type: 'human',
                message: query,
            }
        ]);

        setChatInput('');
    }



    // Entry Point
    useEffect(() => {
        httpClientPy.post(`/chat/history`, {
            project_id: props.project.id,
            record_id: props.selectedId
        })
        .then((response) => {
            let history = response.data.messages;
            setMessages(history);
            let chatWindow1 = document.getElementById('chatList2');
            chatWindow1.scrollTop = chatWindow1.scrollHeight;
        })
        .catch((error) => {
            console.log(error);
        });    


    }, [props.selectedId]);


    const fetchAIAnswer = async (chat_query, last_id) => {
        setGenerating(true);
        
        let requestBody
        let api
        const token = localStorage.getItem('auth_token');

        let chatWindow1 = document.getElementById('chatList2');

        api = '/chat/doc'
        requestBody = JSON.stringify({
            project_id: props.project.id,
            chat_query: chat_query,
            doc_id: props.currentFileName,
            record_id: props.selectedId
        });

        // wait one second to scroll to bottom
        setTimeout(() => {
            chatWindow1.scrollTop = chatWindow1.scrollHeight;
        }, 1000);

        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL_PY + api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: requestBody,
            });


            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let text = '';
            

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                text += decoder.decode(value);
                setAnswer(text);
            }

            httpClientPy.post('/chat/history', {
                project_id: props.project.id,
                record_id: props.selectedId,
            }).then((response) => {
                let newMessages = [...messages];
                newMessages.push({
                    id: response.data.messages[response.data.messages.length - 1].id,
                    user_type: 'ai',
                    message: text,
                });
                setMessages(newMessages);
            }).catch((error) => {
                console.log(error);
            })

        } catch (error) {
            console.error('Error:', error);
        }
        setGenerating(false);
        setAnswer("");
        logEvent('Search', { 'Query': chat_query });
    };
      
    const submitMessage = () => {
        if (chatInput === '') {
            return;
        }
        let lastId = 0;
        if (messages.length > 0) {
            lastId = messages[messages.length - 1].id;
        }

        setMessages(prevMessages => [
            ...prevMessages,
            {
                id: lastId + 1,
                user_type: 'human',
                message: chatInput,
            }
        ]);

        setChatInput('');
    }

    // this is the useEffect to handle when there is new prompt
    useEffect(() => {
        if(messages && messages.length >0){
            let lastMessage = messages[messages.length - 1];
            
            if(lastMessage && lastMessage.user_type === 'human' && lastMessage.message !== '') {
                fetchAIAnswer(lastMessage.message, lastMessage.id);
            }
            if(lastMessage && lastMessage.user_type === 'ai' && lastMessage.message !== '') {

                lastMessage.message.split('\n\n').map((paragraph, index) => {
                    try {
                        let json = JSON.parse(paragraph);
                        if (json.all === false) {
                            delete json.all;
                            delete json.cad;
                            delete json.reg;

                            // get the first key of json
                            let file = Object.keys(json)[0];
                            // get the first key of the first key of json
                            let page = Object.keys(json[file])[0];
                            // get the ref ids of the first key of the first key of json
                            let ref_ids = json[file][page];

                            handleReferenceClick(file, page, ref_ids, lastMessage.id, 'any');
                        }
                    } catch (error) {
                        // do nothing
                    }
                });
                
            }
        }
        // get the last message in the messages together with its type
        let chatWindow = document.getElementById('chatList2');
        chatWindow.scrollTop = chatWindow.scrollHeight;

    }, [messages]);

    // detect if user pressed enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !generating) {
            e.preventDefault();
            if(typingCivilsGPT){
                submitMessage();
            }
            else{
                props.updatePageNumber(parseInt(pageNumberInput))
            }
        }
    }

    // this is the useEffect to handle the keypress event listener
    useEffect(() => {

        // use the handleKeyPress
        window.addEventListener('keypress', handleKeyPress);

        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener('keypress', handleKeyPress);
        };

    });

    const handleReferenceClick = (file, page, ref_ids, msg_id, type) => {
        if (currentFileName === file){
            props.updatePageNumber(parseInt(page));
            props.updateInitialPage(parseInt(page));
        } else {
            httpClientPy.post('/chat/reference', {
                file_name: file,
                project_id: props.project.id
            }).then((response) => {
                props.updatePageNumber(parseInt(page));
                props.updateInitialPage(parseInt(page));
            }).catch((error) => {
                console.log(error);
            })
        }

        httpClientPy.post('/chat/reference/chunk', {
            ref_ids: ref_ids,
            msg_id: msg_id
        })
        .then((response) => {
            props.updateHighlights(response.data.highlight_area);
        })
        .catch((error) => {
            console.log(error);
        });
        
        props.updateClickedMsgID(msg_id);

        if (type !== 'any') {
            props.updateOpenPreview(true)
        }
        logEvent('Reference Clicked', { 'File': file, 'Page': page, 'Type': type });  
    }

    const handleAllReferenceShow = (msg_id) => {
        setAllRefMsgId([...allRefMsgId, msg_id]);
    }

    const handleAllReferenceHide = (msg_id) => {
        setAllRefMsgId(allRefMsgId.filter((id) => id !== msg_id));
    }

    const renderer = (text, msg_id) => {
        try {
            const json = JSON.parse(text);
            if (json.all === false) {
                // remove key 'all' from json
                delete json.all;
                delete json.cad;
                return Object.entries(json).map(([file, pages]) => (
                    <div key={file}>
                        {Object.entries(pages).map(([page_num], index, arr) => (
                            <span key={`${file}-${page_num}`}>
                                <a
                                    className='reference-link circle-owner'
                                    style={{ backgroundColor: '#8e5fd0' }}
                                    onClick={() => handleReferenceClick(file, page_num, pages[page_num], msg_id)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {page_num}
                                </a>
                                {index < arr.length - 1 ? '' : ''}
                            </span>
                        ))}
                    </div>
                ));
            } else if (json.all === true) {
                delete json.all;
                delete json.cad;
                return (
                    <>
                        {!allRefMsgId.includes(msg_id) &&
                            <button className="btn btn-outline-dark btn-sm mt-2" onClick={() => handleAllReferenceShow(msg_id)}>All references</button>
                        }
                        {allRefMsgId.includes(msg_id) &&
                            <div>
                                <div><b>All Reference(s):</b></div>
                                {Object.entries(json).map(([file, pages]) => (
                                    <div key={file}>
                                        {Object.entries(pages).map(([page_num], index, arr) => (
                                            <span key={`${file}-${page_num}`}>
                                                <a
                                                    className='reference-link circle-owner'
                                                    style={{ backgroundColor: '#8e5fd0' }}
                                                    onClick={() => handleReferenceClick(file, page_num, pages[page_num], msg_id)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {page_num}
                                                </a>
                                                {index < arr.length - 1 ? '' : ''}
                                            </span>
                                        ))}
                                    </div>
                                ))}
                                <button className="btn btn-outline-dark btn-sm mb-3" onClick={() => handleAllReferenceHide(msg_id)}>Hide references</button>
                            </div>
                        }
                    </>
                )
            } 
        } catch (error) {
            const html = marked.parse(text);
            return <div dangerouslySetInnerHTML={{ __html: html }} />;
        }
    };    


    // useEffect(() => {
    //     if(pageNumberInput) {
    //         jumpToPage(pageNumberInput - 1);
    //     }
    // }, [pageNumberInput]);


    return (
        <>
            <div id="chatList" className="d-flex flex-column w-100">
                {/* <CardHeader className="bg-light">
                    <h5 className="title-bold">{messages[0].message}</h5>
                </CardHeader> */}
                <div id="chatList2" 
                    className={`bg-offwhite ps-3 pe-1 ${typingCivilsGPT ? 'd-none d-sm-flex flex-column flex-grow-1' : 'd-flex flex-column flex-grow-1'}`} 
                    style={{ 
                        overflowY: 'auto',
                        minHeight: '30dvh', 
                    }}
                >
                    <ul className='chat h-100 py-2 px-3' >

                {messages && messages.length>0 ? 
                    messages.map(data => (
                    <div key={data.id}>
                        {data.user_type === 'human' ? (
                            <li className="self">
                                <div className="msg">
                                    <div className="message"> {data.message}</div>
                                </div>
                            </li>
                        ) : (
                            <li className="other">
                                <div className="msg">
                                    {/* <p><b>AI Suggested Answer:</b></p> */}
                                    {data.message.split('\n\n').map((paragraph, index) => (
                                        <div className="message" key={index}>
                                            {
                                                renderer(paragraph, data.id)
                                            }
                                        </div>
                                    ))}
                                </div>
                            </li>
                        )}
                    </div>
                ))
                :
                <div className="h-100 d-flex align-items-center" key={0}>
                    <div className='row w-100'>
                        <div className='col-4'>
                            <div className='card h-100 card-button' type="button" onClick={()=>genAiAnswer('Create a summary of the key points.')}>
                                <div class="card-body">
                                    <h5 class="card-title primary"><FontAwesomeIcon icon={faBook}></FontAwesomeIcon></h5>
                                    Create a summary of the key points.
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='card h-100 card-button' type="button" onClick={()=>genAiAnswer('Create a table of key risks.')}>
                                <div class="card-body">
                                    <h5 class="card-title primary"><FontAwesomeIcon icon={faTable}></FontAwesomeIcon></h5>
                                    Create a table of key risks.
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='card h-100 card-button' type="button" onClick={()=>genAiAnswer('List out any constraints or requirements.')}>
                                <div class="card-body">
                                    <h5 class="card-title primary"><FontAwesomeIcon icon={faList}></FontAwesomeIcon></h5>
                                    List out any constraints or requirements.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                }


                    {generating && answer && (
                        <li className="other">
                            <div className="msg">
                                <div className="message" dangerouslySetInnerHTML={{ __html: answer }} />
                            </div>
                        </li>
                    )}
                    {generating && !answer && (
                        <li className="other">
                            <div className="msg">
                                <button className="btn border-0 disabled" type="button">
                                    <Spinner size="sm" color="primary" /><span className='ps-2'>I'm searching your project...</span>
                                </button>
                            </div>
                        </li>
                    )}
                </ul>
                
                </div>
            </div>


            <div className='container bg-light px-0 mx-auto px-3 py-2'>
                <div className="d-flex align-items-center">
                    <div className="form-floating text-dark flex-grow-1 me-2">
                        <input 
                            id='question_chat' 
                            autoComplete="off" 
                            onFocus={()=>setTypingCivilsGPT(true)}
                            onBlur={()=>setTypingCivilsGPT(false)}
                            onChange={(e) => handleChangeQuery(e)} 
                            type="text" 
                            value={chatInput}
                            className="form-control gpt_input" 
                            placeholder={"Search for info in your project docs"} 
                        />
                        <label htmlFor='question_chat' className='text-secondary'>
                            Search for info in your project docs
                        </label>
                    </div>
                    {(generating || chatInput.length<1) ? (
                        <button className="btn btn-secondary disabled border-0 btn-lg" disabled type="button">
                            <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                        </button>  
                    ) : (
                        <button className="btn btn-primary border-0 btn-lg" type="button" onClick={() => submitMessage()}>
                            <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                        </button>    
                    )}
                </div>

            </div>
        </>
    )

}

export default SearchAll;


function removePdfExtension(str) {
    return str.endsWith('.pdf') ? str.slice(0, -4) : str;
  }