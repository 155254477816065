import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import httpClientPy from "../../../utils/httpClientPy";
import ValidationSummary from "../ValidationSummary";
import { Progress, UncontrolledAlert, Card, CardHeader, CardBody, Spinner, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown, faBan, faBook, faBoreHole, faFolderTree, faGear, faImage, faLayerGroup, faPen, faPersonDigging, faPlug, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { color } from "d3";
import localStorageService from "../../../utils/localStorageService";
import { useNavigate } from "react-router-dom";
import PricingModal from "../payments/Pricing";
import { logEvent } from "../Mixpanel";

const SearchUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errorCode, setErrorCode] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const fileSize = useRef();
  const [numberOfPdf, setNumberOfPdf] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errors, setErrors] = useState([]);


  const FixedAlert = ({ color, children }) => (
    <div style={{
      position: 'fixed',
      top: 10,
      right: 20,
      width: '30%', // This sets the width to 25% of the screen
      zIndex: 1050
    }}>
      <UncontrolledAlert color={color} className="mb-0">
        {children}
      </UncontrolledAlert>
    </div>
  );

  const handleChange = (files) => {
    if (files.length === 0) {
      return;
    }

    setUploadedFiles(files);
    setUploadedFilesCount(files.length);
  };

    // if there is change to setUploadedFiles and props.mode is null then start upload
    useEffect(() => {
      if(uploadedFiles){
        uploadFiles();
      }
    }
    ,[uploadedFiles]);


  useEffect(() => {

    if(loading){
      const intervalId = setInterval(() => {
        setProgress((prevCount) => {
          if (prevCount >= 100) {
            clearInterval(intervalId);
            return prevCount;
          }
          else{
            return prevCount + 1;
          }
        });
      }, 50*fileSize.current);

      return () => {
        //wait 5 seconds before setting progress 0
        setTimeout(() => {
          clearInterval(intervalId);
          setProgress(0)
        }, 5000)
      };
    }
  }, [loading]);


    const uploadFiles = () => {
      let total_size_mb = 0;
  
      const formData = new FormData();
  
      fileSize.current = 1;
      for (var i = 0; i < uploadedFiles.length; i++) {
        formData.append("file_obj", uploadedFiles[i]);
        // console.log(`file ${i}: ${files[i].name} ${files[i].size} ${files[i].type}`);
        // convert file size to MB
        // increment number of pdf files
        if(uploadedFiles[i].type === "application/pdf"){
          setNumberOfPdf(numberOfPdf+1);
        }
        
        total_size_mb += uploadedFiles[i].size/1000000;
  
        if(uploadedFiles[i].size>300000) {
          fileSize.current += uploadedFiles[i].size/2000000;
        }
      }
  
      let url = ""
        
      if (props.geoRecordId) {
        // append geo record id to the url
        url = `${props.uploadUrl}?geo_record_id=${props.geoRecordId}`;
      } else {
        url = props.uploadUrl;
      }
  
      if (props.projectId) {
        // append project id to the url
        url = `${props.uploadUrl}?project_id=${props.projectId}`;
      }
      
      if (props.mode === null) {
        url = url;
      }
      else if (props.mode === 0) {
        url = `${url}&geotech_data=${false}`;
      }
      else if (props.mode !== 0 && props.mode !== 2 && props.mode !== null) {
        url = `${url}&geotech_data=${true}`;
      }
      else if (props.mode === 2) {
        url = `${url}&drawing=${true}`;
      }

      if (props.mode === 3) {
        url = `/geo/record/upload/reg?project_id=${props.projectId}`;
      }
      
      setLoading(true);
      setShowSuccess(false);
      setShowError(false);
      setErrorCode(0);
      
      httpClientPy
        .post(url, formData)
        .then((response) => {
          setErrorCode(response.data.code);
          if(response.data.code && response.data.code>1){
            setShowError(true)
          }
        })
        .catch((errorResponse) => {
          if (errorResponse.response.data) {
            setErrors(errorResponse.response.data.errors);
          }
          setShowError(true);
        })
        .finally(() => {
          if(!showError) {
            if (props.mode===null) {
              logEvent("Autodetect Doc Upload", { "Event": "Autodetect Report Upload Success" });
            }
            else if (props.mode===0) {
              logEvent("Design Doc Upload", { "Event": "Project Report Upload Success" });
            } else if (props.mode===2) {
              logEvent("CAD Doc Upload", { "Event": "CAD Drawing Upload Success" });
            } else if (props.mode===3) {
              logEvent("Reg Doc Upload", { "Event": "Regulation Upload Success" });
            } 
            setShowSuccess(true);
            props.onComplete();
          }
            // console.log('upload complete')
          setLoading(false);
        });
  
  
    }




  return (
    <>

      {showSuccess && errorCode !== 4 && errorCode !== 5 && errorCode != 11 && (
            (numberOfPdf > 0 && !showError) ? 
              props.mode === 3 ? (
              <FixedAlert color="success"  className="mb-0">
                <span className="title-bold">All set, we're extracting the data. </span><span>&nbsp;We'll email you when your file is ready.</span>
              </FixedAlert>
              ) : props.mode === 1 || 2 || null ? (
              <FixedAlert color="success"  className="mb-0">
                <span className="title-bold">Success! </span><span>&nbsp;Your file is now processing, we'll email you once it's available.</span>
              </FixedAlert>
              ) : (
                null
              )
            :
              !showError && errorCode != 3 ?
              <FixedAlert color="warning"  className="mb-0">
                <span className="title-bold">The uploaded file has records that are already associated with your account.</span>
              </FixedAlert>
              :
                errorCode == 3 &&
                <FixedAlert color="danger"  className="mb-0">
                  <span className="title-bold"><b>Check file type.</b> Only PDF and AGS4 files are currently supported. 
                  Currently only AGS4 is supported.</span>
                </FixedAlert>
      )}

      { errorCode == 4 && (
        <FixedAlert color="warning"  className="mb-0">
          <span className="title-bold">Please upgrade your account. You've reached your document upload limit for your account tier.</span>
        </FixedAlert>
      )}

      { errorCode == 5 && (
        <FixedAlert color="warning"  className="mb-0">
          <span className="title-bold">Please upgrade your account. You've reached your document upload limit for your account tier.</span>
        </FixedAlert>
      )}

      { errorCode == 6 && (
        <FixedAlert color="warning"  className="mb-0">
          <span className="title-bold">This file exceeds the size limit of {props.mode == 2 ? '300' : "32"}MB</span><span> - please upload a smaller document</span>
        </FixedAlert>
      )}

      { errorCode == 7 && (
        <FixedAlert color="warning"  className="mb-0">
          <span className="title-bold">File type isn't a PDF</span><span> - please upload a PDF file instead.</span>
        </FixedAlert>
      )}

      { errorCode == 11 && (
        <FixedAlert color="warning"  className="mb-0">
          <span className="title-bold">Uploaded PDF File is not readble. We recommend upgrading to Basic or enterprise to utilize more powerful document extractor</span>
        </FixedAlert>
      )}
      { errorCode == 12 && (
        <FixedAlert color="warning"  className="mb-0">
          <span className="title-bold">Your account has view only permission, please speak with project admin</span>
        </FixedAlert>
      )}

      {showError && errors && errors.length > 0 && (
        <ValidationSummary errors={errors} />
      )}

      {showError && (errors && errors.length > 0) && (
        <FixedAlert color="danger"  className="mb-0">
          <span className="title-bold">Sorry something went wrong!</span><span>&nbsp;Please try uploading your file again later.</span>
        </FixedAlert>
      )}
      <div className="drag-drop-uploader-holder h-100">
        {!showError ?
        <>
          {(loading) &&
            <>
                <div className="drag-drop-uploader h-100 disabled d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
                  <div>
                    <Spinner color="dark" size="sm" className="mx-auto" />
                    <span>File upload in progress</span>
                  </div>
                  <div
                    className="progress w-100"
                    style={{ height: '1rem', borderRadius: 0, margin: 'auto', transition: 'none' }}
                  >
                    <div
                      className={`progress-bar custom-progress-bar ${progress === 100 && 'bg-success'}`}
                      role="progressbar"
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${progress}%` }}
                    >
                      {progress}%
                    </div>
                  </div>
                </div>
            </>
          }
          {
            <FileUploader
              classes={`drag-drop-uploader h-100 w-100 mb-2 ${(loading || (progress > 0 && progress < 100)) && 'd-none'}`}
              style={{ minWidth: "100%" }}
              label={`Drop ${props.mode === 0 ? 'reports' : props.mode === 1 ? 'geotech' : props.mode === 2 ? 'CAD' : props.mode === 3 ? 'regs' : null } here`}
              hoverTitle={`Drop ${props.mode === 0 ? 'reports' : props.mode === 1 ? 'boreholes' : props.mode === 2 ? 'CAD' : props.mode === 3 ? 'regs' : null } here`}
              name="file"
              types={["pdf"]}
              handleChange={handleChange}
              maxSize={props.mode == 2 ? 300 : 32}
              onSizeError={()=>{setErrorCode(6)}}
              onTypeError={()=>setErrorCode(7)}
              multiple={true}
            />
          }
        </>
        :
        <div className="drag-drop-uploader disabled d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
            <span>Upload error, please try again</span>
            <button onClick={()=>{setErrorCode(0); setShowError(false);}} className="btn mt-2 btn-sm btn-primary">Try again</button>
        </div>
        }
      </div>
    </>
  )
};

export default SearchUploader;
