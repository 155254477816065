import Loader from "../../shared/Loader";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import moment, { max } from "moment";
import { useEffect, useState, useRef } from "react";
import GeoRecordDetailsButton from "../../geoRecords/geoRecordDetailsButton";

import Button from "../../shared/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Modal, Card, CardBody, Alert, Table, Input, FormGroup, Tooltip, CardHeader } from "reactstrap";
import { faFileExcel, faLocationDot, faMapPin, faFilePdf, faFileCode, faEye, faMapLocation, 
        faDatabase, faList, faClock, faPenToSquare, faLock, faLockOpen, faLocationCrosshairs, faTriangleExclamation, faCloudArrowUp, faTrash, faSquarePollHorizontal, faTag, faBook, faMap, faMagnifyingGlass, faPieChart, faMagnifyingGlassChart } from "@fortawesome/free-solid-svg-icons";
import DataTable from 'react-data-table-component';
import httpClientPy from "../../../utils/httpClientPy";
import { logEvent } from "../../shared/Mixpanel";

library.add(faFileExcel, faMapPin, faLocationDot, faFilePdf, faMapLocation, faFileCode, faEye, faDatabase,faTriangleExclamation, faList, faClock, faCloudArrowUp);

const SearcHistory = (props) => {

    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);

    const searchHistoryRetrieval = async (text) => {
        setLoading(true);
        try {
            let response = await httpClientPy.post(`/search/history`, { project_id: props.project_id });
            let history = response.data.messages;
            
            let historyList = [];
            history.forEach((message) => {
                if (message.user_type === 'human') {
                    historyList.push({
                        id: message.id,
                        text: message.message,
                        num_of_ref_files: message.num_of_ref_files,
                        is_ref_design_doc: message.is_ref_design_doc,
                        is_ref_cad: message.is_ref_cad,
                        is_ref_reg: message.is_ref_reg,
                        created_datetime: message.created_at,
                        user_type: message.user_type
                    });
                }
            });
            logEvent('Search History');
            setHistory(historyList);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
      }

    useEffect(() => {
        searchHistoryRetrieval();
    }, []);

    const columns = [
        {
            name: 'Search Query',
            selector: row => row.text,
            width: '320px',
            center: true,
            sortable: true,
            cell: (row) => {
                return (
                        row.text
                );
            },
        },
        {
            name: 'Date searched',
            id: 'created_datetime',
            selector: row => row.created_datetime ? new Date(row.created_datetime) : new Date(0), // Return raw datetime for sorting
            width: '170px',
            center: true,
            sortable: true,
            cell: (row) => (
                <span>
                {row.created_datetime
                    ? moment(row.created_datetime).format('DD/MM/YYYY')
                    : 'Unknown'}
                </span>
            ),
        },
        {
            name: 'Results found',
            selector: row => row.num_of_ref_files,
            minWidth: '100px',
            center: true,
            sortable: true,
            cell: (row) => {
                if(row.num_of_ref_files) {
                    return (
                        <span className={`badge rounded-pill green`}>
                            {row.num_of_ref_files}
                        </span>
                    );
                } else {
                    return (
                        <span className={`badge rounded-pill red`}>
                            0
                        </span>
                    );
                }
            },
        },
        {
            name: 'Reports found',
            selector: row => row.is_ref_design_doc,
            minWidth: '100px',
            center: true,
            sortable: true,
            cell: (row) => {
                if(row.is_ref_design_doc) {
                    return (
                        <span className={`badge rounded-pill green`}>
                            Yes
                        </span>
                    );
                } else {
                    return (
                        <span className={`badge rounded-pill red`}>
                            No
                        </span>
                    );
                }
            },
        },
        {
            name: 'Regs found',
            selector: row => row.is_ref_reg,
            minWidth: '100px',
            center: true,
            sortable: true,
            cell: (row) => {
                if(row.is_ref_reg) {
                    return (
                        <span className={`badge rounded-pill green`}>
                            Yes
                        </span>
                    );
                } else {
                    return (
                        <span className={`badge rounded-pill red`}>
                            No
                        </span>
                    );
                }
            },
        },
        {
            name: 'CAD found',
            selector: row => row.is_ref_cad,
            minWidth: '100px',
            center: true,
            sortable: true,
            cell: (row) => {
                if(row.is_ref_cad) {
                    return (
                        <span className={`badge rounded-pill green`}>
                            Yes
                        </span>
                    );
                } else {
                    return (
                        <span className={`badge rounded-pill red`}>
                            No
                        </span>
                    );
                }
            },
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: 'bold',
                zIndex: 0,
            },
        },
        rows: {
            style: {
            cursor: 'pointer',
            },
        },
    };

    const handleRowClick = (row) => {
        props.handleSearchHistoryClicked(row.id);
        setLoading(true)
    }

    return (
        <div>
            <div className="">
            <Card className="h-100 border-0 ">
            <CardHeader>
                <h3 className='title-bold pb-0 mb-0 text-center'>Search History</h3>
            </CardHeader>
            {loading ?
                <div className="my-5">
                    <Loader loading={loading} />
                </div>
                :
                <CardBody>
                        {history && history.length > 0 && (
                            <>    
                            <DataTable
                            className="sticky-top bg-white text-center geo-records-list-holder px-2"
                            pagination
                            paginationRowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                            paginationPerPage={10}
                            searchable
                            highlightOnHover
                            defaultSortFieldId="uploaded" 
                            defaultSortAsc={false}
                            columns={columns}
                            data={history}
                            onRowClicked={handleRowClick} // Add this line to handle row click
                            customStyles={customStyles}
                            />
                            </>
                        )}
                        {(!history || history.length === 0) && !loading && (
                            <>
                            <h6 className="text-muted text-center pt-3">
                                No search history found.
                            </h6>
                            </>
                        )}

                    </CardBody>
                }
            </Card>
            </div>
        </div>
    );
};

export default SearcHistory;
