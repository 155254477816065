import React, { useState, useEffect} from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';

import { Spinner, Tooltip } from 'reactstrap';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity, faMagnifyingGlass, faMagnifyingGlassArrowRight, faMagnifyingGlassChart, faMessage, faWarning } from '@fortawesome/free-solid-svg-icons';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';


const PdfViewer = (props) => {
    
    const [fileUrl, setFileUrl] = useState(false);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);

    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);


    const pageNavigationPluginInstance = pageNavigationPlugin();
    const zoomPluginInstance = zoomPlugin();

    const { jumpToPage } = pageNavigationPluginInstance;


    useEffect(() => {
        if(props.pageNumberInput) {
            jumpToPage(props.pageNumberInput - 1);
        }
    }, [props.pageNumberInput]);

    const renderHighlights = (properties) => {
        if (!props.highlightArea || props.highlightArea.length === 0) {
            return null;
        }
    
        return (
            <div>
                {props.highlightArea
                    .filter((area) => area.pageIndex === properties.pageIndex)
                    .map((area, idx) => (
                        <div
                            key={idx}
                            className="highlight-area"
                            style={Object.assign(
                                {},
                                {
                                    background: 'purple',
                                    opacity: 0.2,
                                },
                                properties.getCssProperties(area, properties.rotation)
                            )}
                        />
                    ))}
            </div>
        );
    };

    const highlightPluginInstance = highlightPlugin({
        renderHighlights,
        trigger: Trigger.None,
    });

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;


    const CustomToolbar = (props) => (
        <Toolbar>
            {(slots) => {
                const {
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    CurrentPageInput,
                    ZoomOut,
                    ZoomIn,
                    Download,
                    // ... other slots you want to keep
                } = slots;
    
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ padding: '0px 2px' }}>
                                <GoToPreviousPage />
                            </div>
                            <div style={{ padding: '4px 2px', display: 'flex', alignItems: 'center' }}>
                                <CurrentPageInput /> / <NumberOfPages />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <GoToNextPage />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomIn />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <Download />
                            </div>
                            <div>
                                {props.currentFileName}
                            </div>
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    const handlePageChanged = (e) => {
        console.log(e)
        // if(props.initialPage === null) {
        //     props.updatePageNumber(e.currentPage + 1)
        // } else {
        //     props.updatePageNumber(props.initialPage)
        //     // setTimeout(() => {
        //     //     props.setInitialPage(null)
        //     // }, 1000)
        // }
    };

    return (
        <>
            <div>
                <CustomToolbar />
            </div>
            <div id="pdf_viewer" className="h-100 mt-2 position-relative">
                <div className="row h-100 align-content-center">
                    {props.fileUrl &&
                        <Viewer 
                            fileUrl={props.fileUrl} 
                            onPageChange={handlePageChanged}
                            initialPage={props.initialPage - 1}
                            enableSmoothScroll={true}
                            plugins={[
                                pageNavigationPluginInstance, 
                                highlightPluginInstance,
                                toolbarPluginInstance,
                            ]} 
                        />
                    }
                </div>
                <div id="search_tools" className={`${props.task !== 0 ? 'd-none d-sm-block' : 'd-none'}`} style={{ zIndex: 9999 }}>
                    <div>
                    <div id="tooltip1" className={`btn btn-sm btn-panel border mb-3 ${props.task === 1 && 'active'}`} onClick={()=>props.updateTask(1)}>
                        <FontAwesomeIcon icon={faCity} width={'1rem'} />
                    </div>
                    <Tooltip isOpen={tooltipOpen1} placement={'left'} target="tooltip1" toggle={toggle1}>
                        Search all project
                    </Tooltip>
                    </div>
                    <div>
                    <div id="tooltip2" className={`btn btn-sm btn-panel mb-3 border ${props.task === 2 && 'active'}`} onClick={()=>props.updateTask(2)}>
                        <FontAwesomeIcon icon={faMagnifyingGlassArrowRight} />
                    </div>
                    <Tooltip isOpen={tooltipOpen2} placement={'left'} target="tooltip2" toggle={toggle2}>
                        Search open document
                    </Tooltip>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PdfViewer;
