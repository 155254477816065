import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import httpClientPy from "../../../utils/httpClientPy";
import ValidationSummary from "../ValidationSummary";
import { Progress, UncontrolledAlert, Card, CardHeader, CardBody, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoreHole, faGear, faMagnifyingGlass, faPen, faPersonDigging, faPlug, faPlus, faTrash, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { color } from "d3";
import localStorageService from "../../../utils/localStorageService";
import { useNavigate } from "react-router-dom";

const DraftReportUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [progress, setProgress] = useState(0);
  const [progress2, setProgress2] = useState(0);

  const fileSize = useRef();
  const [agsUpload, setAgsUpload] = useState(false);
  const [numberOfPdf, setNumberOfPdf] = useState(0);
  const [errorCode, setErrorCode] = useState(0);
  const [geotechData, setGeotechData] = useState(false);
  const [isFreeUser, setIsFreeUser] = useState(null);
  const [modalPricingOpen, setModalPricingOpen] = useState(false);
  const [digitiseMode, setDigitiseMode] = useState('4');

  const togglePricingModal = () => setModalPricingOpen(!modalPricingOpen);
  const navigate = useNavigate();

  const navigateToProjectData = (type) => {
    navigate(`/project/data`, {state: {project: props.project, type: type}});
  }

  useEffect(() => {
    
    if (props.userInfo && !props.userInfo.is_enterprise && props.userInfo.subscription_details && props.userInfo.subscription_details.subscription_type === "free") {
      setIsFreeUser(true);
    } else {
      setIsFreeUser(false);
    }
    
    if(props.userInfo && props.userInfo.allow_digitise && props.userInfo.allow_civilsgpt) {

      if (localStorageService.getItem("geotechDataHolder") !== null) {
        setGeotechData(localStorageService.getItem("geotechDataHolder"));
      }
      else{
        setGeotechData('1');
      } 
    }
    else if(props.userInfo && props.userInfo.allow_digitise && !props.userInfo.allow_civilsgpt) {
      setGeotechData('3');
    }
    else if (props.userInfo && !props.userInfo.allow_digitise && props.userInfo.allow_civilsgpt) {
      setGeotechData('1');
    }

    const handleFileDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        handleChange(files);
    }

    window.addEventListener("dragover", (e) => e.preventDefault());
    window.addEventListener("drop", handleFileDrop);
    return () => {
      window.removeEventListener("dragover", (e) => e.preventDefault());
      window.removeEventListener("drop", handleFileDrop);
    };

  }, []);


  const toggleGeotechData = (event) => {
    let status = event.target.value;
    setGeotechData(status);
    localStorageService.setItem("geotechDataHolder", status);
}


  useEffect(() => {

    if(loading){
      const intervalId = setInterval(() => {
        setProgress((prevCount) => {
          if (prevCount >= 100) {
            clearInterval(intervalId);
            setLoading2(true);
            return prevCount;
          }
          else{
            return prevCount + 1;
          }
        });
      }, 50*fileSize.current);

      return () => {
        clearInterval(intervalId);
        setLoading2(true);
        setProgress(0)
      };
    }
  }, [loading]);
  

  useEffect(() => {

    if(loading2){
      const intervalId = setInterval(() => {
        setProgress2((prevCount2) => {
          if (prevCount2 >= 100) {
            clearInterval(intervalId);
            return prevCount2;
          }
          else{
            return prevCount2 + 1;
          }
        });
      }, 50*fileSize.current);

      return () => {
        clearInterval(intervalId);
        setProgress2(0)
      };
    }
  }, [loading2]);



  useEffect(() => {
    if(showSuccess){
      setTimeout(() => {
        setShowSuccess(false)
      }, 3000)
    }
  }, [showSuccess]);


  useEffect(() => {
    if(errorCode === 4 || errorCode === 11 || errorCode === 5){
      setModalPricingOpen(true);
    }
  }, [errorCode]);


  const handleChange = (files) => {
    setNumberOfPdf(0);
    let total_size_mb = 0;
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    fileSize.current = 1;
    for (var i = 0; i < files.length; i++) {
      formData.append("file_obj", files[i]);
      // console.log(`file ${i}: ${files[i].name} ${files[i].size} ${files[i].type}`);
      // convert file size to MB
      // increment number of pdf files
      if(files[i].type === "application/pdf"){
        setNumberOfPdf(numberOfPdf+1);
      }
      
      total_size_mb += files[i].size/1000000;

      if(files[i].size>300000) {
        fileSize.current += files[i].size/2000000;
      }
    }

    let url = ""
    if (props.geoRecordId) {
      // append geo record id to the url
      url = `${props.uploadUrl}?geo_record_id=${props.geoRecordId}`;
    } else {
      url = props.uploadUrl;
    }

    if (props.projectId) {
      // append project id to the url
      url = `${props.uploadUrl}?project_id=${props.projectId}`;
    }

    
    if(files[0].type !== "application/pdf"){ // if ags then true for geotech data
      url = `${url}&geotech_data=${true}`;
    } else {
      if(geotechData === '1' || geotechData === '2') {
        url = `${url}&geotech_data=${false}`;
      }
      else if(geotechData === '3') {
        url = `${url}&geotech_data=${true}`;
      }
    }
    
    setProgress2(0)
    setLoading(true);
    setLoading2(false);
    setShowSuccess(false);
    setShowError(false);
    setAgsUpload(false);
    setErrorCode(0);
    
    httpClientPy
      .post(url, formData)
      .then((response) => {
        setErrorCode(response.data.code);
        if(response.data.info.length > 0){
          setAgsUpload(true);
          props.updateAgsInfo(response.data.info);
        }
      })
      .catch((errorResponse) => {
        if (errorResponse.response.data) {
          setErrors(errorResponse.response.data.errors);
        }
        setShowError(true);
      })
      .finally(() => {
        if(!showError) {
          setShowSuccess(true);
          props.onComplete();
        }
          // console.log('upload complete')
        setLoading(false);
      });
  };


  return (
    <>
          <div id="chat_input_container" className="w-100 text-dark">


            <div className="w-100">
              {showSuccess && errorCode !== 4 && errorCode !== 5 && errorCode != 11 && (
                agsUpload && !showError ?
                  <UncontrolledAlert color="success" className="mb-0">
                    <span className="title-bold">All set, your AGS files are extracted </span><span>&nbsp;You can view the records in my geotech data.</span>
                  </UncontrolledAlert>
                  :
                    (numberOfPdf > 0 && !showError) ? 
                      geotechData === '3' ? (
                      <UncontrolledAlert color="success"  className="mb-0">
                        <span className="title-bold">All set, we're extracting the data. </span><span>&nbsp;We'll email you when your file is ready.</span>
                      </UncontrolledAlert>
                      ) : geotechData === '1' || '2' ? (
                      <UncontrolledAlert color="success"  className="mb-0">
                        <span className="title-bold">Success! </span><span>&nbsp;Your file is now processing, we'll email you once it's available.</span>
                      </UncontrolledAlert>
                      ) : (
                        null
                      )
                    :
                      !showError && errorCode != 3 ?
                      <UncontrolledAlert color="warning"  className="mb-0">
                        <span className="title-bold">The uploaded file has records that are already associated with your account.</span>
                      </UncontrolledAlert>
                      :
                        errorCode == 3 &&
                        <UncontrolledAlert color="danger"  className="mb-0">
                          <span className="title-bold"><b>Check file type.</b> Only PDF and AGS4 files are currently supported. 
                          Currently only AGS4 is supported.</span>
                        </UncontrolledAlert>
              )}

              { errorCode == 4 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">Please upgrade. As a Free user you are limited to one PDF file upload.</span>
                </UncontrolledAlert>
              )}

              { errorCode == 5 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">As a Basic user, your account is currently limited to uploading a maximum of 20 PDF files.</span>
                </UncontrolledAlert>
              )}

              { errorCode == 6 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">This file exceeds the size limit of {geotechData == '3' ? '300' : isFreeUser ? '10' : "32"}MB</span><span> - please upload a smaller document</span>
                </UncontrolledAlert>
              )}

              { errorCode == 7 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">File type isn't a PDF</span><span> - please upload a PDF file instead.</span>
                </UncontrolledAlert>
              )}

              { errorCode == 11 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">Uploaded PDF File is not readble. We recommend upgrading to Basic or enterprise to utilize more powerful document extractor</span>
                </UncontrolledAlert>
              )}

              {showError && errors && errors.length > 0 && (
                <ValidationSummary errors={errors} />
              )}

              {showError && !(errors && errors.length > 0) && (
                <UncontrolledAlert color="danger"  className="mb-0">
                  <span className="title-bold">Sorry something went wrong!</span><span>&nbsp;Please try uploading your file again later.</span>
                </UncontrolledAlert>
              )}
            </div>


          {props.userInfo &&
          <div className="uploader_holder w-100 mx-auto">

            <div className="row justify-content-center g-0 my-5">
              <div className="col-3">
                <Card className="shadow-sm bg-light">
                  <CardHeader>
                    <h6 className="title-bold"><FontAwesomeIcon icon={faGear} /> Mode</h6>
                  </CardHeader>
                  <CardBody>
                  <div className="mb-4">
                  <select 
                    className="form-select" 
                    onChange={(event) => setDigitiseMode(event.target.value)} 
                    value={digitiseMode.toString()} 
                    aria-label="Default select example"
                  >
                    {props.userInfo && props.userInfo.allow_digitise && props.userInfo.allow_civilsgpt ?
                      <>
                        <option value="4">Report drafter</option>
                      </>
                      : props.userInfo && props.userInfo.allow_digitise && !props.userInfo.allow_civilsgpt ?
                      <>
                        <option value="4">Report drafter</option>
                      </>
                      : props.userInfo && !props.userInfo.allow_digitise && props.userInfo.allow_civilsgpt ?
                      <>
                        <option value="4">Report drafter</option>
                      </>
                      :
                      <>
                        <option value="4">Report drafter</option>

                      </>
                    }
                  </select>

                  <select 
                    className="form-select mt-2" 
                    aria-label="file type"
                  >
                    <option value="all">All files</option>
                    <option value="all">Specific documents</option>
                  </select>

                  <select 
                    className="form-select mt-2" 
                    aria-label="file type"
                  >
                    <option value="all">All time</option>
                    <option value="all">Last week</option>
                    <option value="all">Last month</option>
                    <option value="all">Custom range</option>
                  </select>



                    </div>

                  </CardBody>
                </Card>

              </div>
              <div className="col-1 pt-5">
                    <Progress max={100} value={progress} animated color={progress === 100 ? 'success' : 'primary'} className="my-2" style={{ width: '100%', borderRadius:0, margin: 'auto' }}>{progress}%</Progress>
              </div>
              <div className="col-4">

                <Card className="shadow-sm bg-light">
                    <CardHeader>
                      <h6 className="title-bold"><FontAwesomeIcon icon={faMagnifyingGlass} /> Search</h6>
                    </CardHeader>
                    <CardBody>
                      <div>
                      {digitiseMode === '3'  && (
                        <>
                        <div className="title-bold"><small>Report headings:</small></div>
                        <div>
                          <input type="text" class="form-control form-control-sm mt-1" disabled value="Project name" aria-label="Project name" aria-describedby="basic-addon1"/>
                          <input type="text" class="form-control form-control-sm mt-1" disabled value="Borehole name" aria-label="Borehole name" aria-describedby="basic-addon1"/>
                          <input type="text" class="form-control form-control-sm mt-1" disabled value="Coordinates" aria-label="Coordinates" aria-describedby="basic-addon1"/>
                          <input type="text" class="form-control form-control-sm mt-1" disabled value="Geological descriptions" aria-label="Geological descriptions" aria-describedby="basic-addon1"/>
                          <input type="text" class="form-control form-control-sm mt-1" disabled value="Geological thicknesses" aria-label="Geological thicknesses" aria-describedby="basic-addon1"/>
                          <input type="text" class="form-control form-control-sm mt-1" disabled value="SPT" aria-label="SPT" aria-describedby="basic-addon1"/>
                          <input type="text" class="form-control form-control-sm mt-1" disabled value="RQD, TCR, SCR" aria-label="RQD, TCR, SCR" aria-describedby="basic-addon1"/>
                          <input type="text" class="form-control form-control-sm mt-1" disabled value="Shear Vane" aria-label="Shear Vane" aria-describedby="basic-addon1"/>
                        </div>
                        </> 
                        )
                      }
                      {digitiseMode === '4'  && (
                        <>
                          <div className="title-bold"><small>Report headings:</small></div>
                          <div>
                            <div class="input-group input-group-sm mb-2">
                              <input type="text" class="form-control" aria-label="Project name" />
                              <button class="btn btn-sm overview_button border-secondary" type="button" id="button-addon2">
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                            <div class="input-group input-group-sm mb-2">
                              <input type="text" class="form-control" aria-label="Borehole name" />
                              <button class="btn btn-sm overview_button border-secondary" type="button" id="button-addon2">
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                            <div class="input-group input-group-sm mb-2">
                              <input type="text" class="form-control" aria-label="Coordinates" />
                              <button class="btn btn-sm overview_button border-secondary" type="button" id="button-addon2">
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                            <div class="input-group input-group-sm mb-2">
                              <input type="text" class="form-control" aria-label="Geological descriptions" />
                              <button class="btn btn-sm overview_button border-secondary" type="button" id="button-addon2">
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                          </div>
                          <div className="text-center mt-2">
                            <button className="btn btn-sm overview_button border-secondary">
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          </div>
                        </>

                        )}
                      </div>
                      </CardBody>
                      
                  </Card>

              </div>
              <div className="col-1 pt-5">
                    <Progress max={100} value={progress2} animated color={progress2 === 100 ? 'success' : 'primary'} className="my-2" style={{ width: '100%', borderRadius:0, margin: 'auto' }}>Extracting</Progress>
              </div>

              <div className="col-2">
                  <Card className="shadow-sm bg-light">
                    <CardHeader>
                      <h6 className="title-bold"><FontAwesomeIcon icon={faPen} /> Output</h6>
                    </CardHeader>
                    <CardBody>
                      <input type="text" disabled class="form-control form-control-sm" value="Database" aria-label="Geological descriptions" />
                      <input type="text" disabled class="form-control form-control-sm mt-1" value="Word document" aria-label="Geological descriptions" />
                      {digitiseMode === '3' && (
                        <>
                          <input type="text" disabled class="form-control form-control-sm mt-1" value="DXF" aria-label="Geological descriptions" />
                          <input type="text" disabled class="form-control form-control-sm mt-1" value="GIS" aria-label="Geological descriptions" />
                          <input type="text" disabled class="form-control form-control-sm mt-1" value="AGS" aria-label="Geological descriptions" />
                        </>
                      )}
                      <div className="btn btn-sm mt-2 w-100 border-secondary overview_button" onClick={() => navigateToProjectData("all")}>
                              <span>
                                  View data ({props.extraDetails.ready_geotech_data + props.extraDetails.ready_non_geotech_data})
                              </span>
                      </div>
                    </CardBody>
                  </Card>
              </div>
            </div>

            {props.extraDetails && (props.extraDetails.pending_geotech_data > 0 || props.extraDetails.pending_non_geotech_data > 0) && 
                <div className="text-end">
                  <div className="btn btn-sm btn-outline-secondary bg-white disabled">
                    <Spinner color="success" size="sm"/>
                    
                    <span className="text-dark ps-2">{props.extraDetails.pending_geotech_data + props.extraDetails.pending_non_geotech_data} Background task(s) in progress</span>
                  </div>
                </div>
            }

          </div>
        }
      </div>
    </>
  );
};

export default DraftReportUploader;
