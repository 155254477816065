import {Card, CardBody, CardTitle, CardHeader, Spinner, Tooltip, Alert, CardFooter} from "reactstrap";
import { useEffect, useState, useRef } from "react";
import httpClientPy from "../../../utils/httpClientPy";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfoCircle, faLocationDot, faPenToSquare, faUsers, faUser, faPaperPlane, faCircleUser, faRobot, faClockRotateLeft, faLock, faEarthEurope, faDrawPolygon, faComment, faInfo, faRefresh, faPlug, faMagnifyingGlass, faShareFromSquare} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "reactstrap";
import AgsUploaderVerify from "../../map/dataTab/AgsUploaderVerify";
import NewProjectModal from "../SideBarComponents/NewProjectModal";
import ShareProjectModal from "./ShareProjectModal";

import Uploader from "../../shared/Uploader";
import { MapContainer, TileLayer, Polyline, Marker} from 'react-leaflet';
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import EditProjectDropdown from "./EditProjectDropdown";
import SearcHistory from "./SearchHistory";

const ProjectOverview = (props) => {

    const navigate = useNavigate();
    const [projectNameInputFocus, setProjectNameInputFocus] = useState(false);
    const [projectNameChanged, setProjectNameChanged] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [project, setProject] = useState(props.project);
    const [editProject, setEditProject] = useState(false);
    const [agsInfo, setAgsInfo] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [sharingOpen, setSharingOpen] = useState(false);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [toggleUpdateProjectLocation, setToggleUpdateProjectLocation] = useState(false);
    const [automation1, setAutomation1] = useState(true);
    const [automation2, setAutomation2] = useState(true);
    const [chatInput, setChatInput] = useState('');
    const inputRef = useRef(null);
    const [searching, setSearching] = useState(false);

    let styleHolder = [
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            {
              saturation: "-100",
            },
          ],
        },
        {
          featureType: "administrative.province",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [
            {
              saturation: "-100",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              saturation: -70,
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels",
          stylers: [
            {
              lightness: -25,
            },
            {
              saturation: -100,
            },
          ],
        },
    ]


    const toggleCollapse = (collapseId) => {
        if (collapseId === 'automation1') {
            setAutomation1(!automation1);
        }
        else if (collapseId === 'automation2') {
            // set 1 second timeout
            // setTimeout(() => {
            //     inputRef.current.focus();
            // }, 1000);
            setAutomation2(!automation2);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
              }
        }, 1000);

        // use the handleKeyPress
        window.addEventListener('keypress', handleKeyPress);
        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener('keypress', handleKeyPress);
        };
    },[]);

    const handleUpdateProjectLocationClick = (project_type) => {
        navigate('/map', { state: {project_update_location: true, project_type: project_type, project_detail: project} });
    }

    const saveProject = () => {

        if(project.name && project.name.length > 1) {
            httpClientPy.post(
                `/project/update`, 
                {
                    project_id: project.id,
                    update_type: "name",
                    name: project.name
                }
            )
            .then((response) => {
                setProjectNameChanged(false);
            })
            .catch((err) => {
                setError(true);
                setErrorMessage(err.response.data.detail);
            }
            );
        }
        props.getProjectData(project.id);
        setEditProject(false);
    }


    const handleEditProjectName = (e) => {
        setProject({ ...project, name: e.target.value });
        setProjectNameChanged(true);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setSearching(true)
            e.preventDefault();
            let chatInput = document.getElementById('question_chat');
            if(chatInput.value.length > 0) {
                props.handleChat(chatInput.value);
            }
        }
    }

    const handleChatClick = () => {
        setSearching(true)
        props.handleChat(chatInput);
    }

    const handleHistory = () => {
        toggleHistory();
    }


    const toggleSharing = () => {
        setSharingOpen(!sharingOpen);
    }

    const toggleHistory = () => {
        setHistoryOpen(!historyOpen);
    }

    const updateAgsInfo = (data) => {
        setAgsInfo(data);
    };

    const onToggleAgsVerifier = () => {
        setAgsInfo([]);
    };

    const handleChangeQuery = (e) => {
        setChatInput(e.target.value);
    }

    const toggleUpdateProjectLocationModal = () => {
        setToggleUpdateProjectLocation(!toggleUpdateProjectLocation);
    }

    return (
        <>
            <Card 
                className={`default-card d-flex flex-column vh-100-true`}
            >
                <CardBody className="d-flex flex-column  flex-grow-1 overflow-auto container">
                    {!error ? (
                        <div>

                            {props.extraDetails && props.extraDetails.all_ready_data && props.extraDetails.all_ready_data > 0 ?
                                null

                                :
                                <Alert className="shadow-sm" color="warning">
                                    👋
                                    <span className="ms-2"><b>Getting started is easy</b> - upload a PDF document below to start building your project archive</span>
                                </Alert>
                            }

                                <div className="row">
                                    {props.displayMap && (
                                        <div className="col-2 d-none d-lg-block">
                                                <>
                                                <MapContainer
                                                    className="map-project-details rounded shadow-sm"
                                                    center={[props.polygonCenter[0], props.polygonCenter[1]]}
                                                    zoom={props.zoomLevel}
                                                    zoomControl={false}
                                                    attributionControl={false}
                                                    dragging={false}
                                                    
                                                    scrollWheelZoom={false}
                                                    preferCanvas={true}
                                                    doubleClickZoom={false}
                                                    >
                                                    <ReactLeafletGoogleLayer
                                                    type="roadmap"
                                                    styles={styleHolder}
                                                    useGoogMapsLoader={false}
                                                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                                    >
                                                    </ReactLeafletGoogleLayer>
                                                    {project.project_type !== 1 &&
                                                    <Polyline pathOptions={{color: 'purple', fill: true, weight:2,}} positions={props.polygon} />
                                                    }

                                                </MapContainer>
                                                </>
                                        </div>
                                    )}
                                    <div className="col">

                                        <>
                                            <Card className="w-100 bg-light shadow-sm pb-1">

                                                <CardHeader className="bg-light border-0">
                                                    {editProject ? (
                                                        <>
                                                        <div id="chat_input_container" className="d-block d-md-flex input-group-lg input-group text-dark">
                                                            <input type="text" className="form-control gpt_input title-bold" 
                                                                        value={project.name} 
                                                                        onChange={handleEditProjectName}
                                                                        />
                                                            <button className="btn btn-primary border-0" type="button" onClick={() => saveProject()}>
                                                                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                                                            </button>
                                                        </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="d-block d-md-flex text-center text-md-start justify-content-between pt-2">
                                                                <span className="d-block d-md-flex text-center text-md-start align-items-center  pb-2 pb-md-0">
                                                                    <h4 className="title-bold d-inline mb-0"><b>{project.name}</b></h4>
                                                                </span>
                                                                <span id="project_buttons" className="ms-3 text-end">
                                                                    {props.userInfo && props.userInfo.george_id === project.owner_id &&
                                                                        <EditProjectDropdown
                                                                            editProjectName={() => setEditProject(true)}
                                                                            editProjectLocation={() => toggleUpdateProjectLocationModal()}
                                                                        />
                                                                    }


                                                                    {props.userInfo && props.userInfo.allow_civilsgpt && props.extraDetails && (props.extraDetails.ready_non_geotech_data > 0 || props.extraDetails.ready_cad_data > 0 || props.extraDetails.ready_reg_data > 0) ?

                                                                        <button className="btn title-bold border-secondary btn-sm overview_button ms-2" onClick={() => handleHistory()}>
                                                                        <FontAwesomeIcon icon={faClockRotateLeft}></FontAwesomeIcon>
                                                                        <span className="ps-2">Search history</span>
                                                                        </button>
                                                                        : null
                                                                    }
                                                                    {props.userInfo && props.userInfo.george_id === project.owner_id && props.userInfo.is_enterprise &&
                                                                        <button className="btn title-bold border-secondary btn-sm overview_button ms-2" onClick={() => toggleSharing()}>
                                                                            <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
                                                                            <span className="ps-2">Share</span>
                                                                        </button>
                                                                    }

                                                                </span>
                                                            </div>

                                                        </>
                                                    )}                                                                                            
                                            </CardHeader>

                                                    <CardBody className={`bg-light ${automation2 ? 'collapse show' : 'collapse'}`} id="automation2">

                                                        <div id="chat_input_container" className="flex-container input-group input-group-lg input-group text-dark">

                                                            {(props.extraDetails.ready_non_geotech_data > 0 || props.extraDetails.ready_cad_data > 0 || props.extraDetails.ready_reg_data > 0) > 0  && props.userInfo && props.userInfo.allow_civilsgpt ? (
                                                                <>
                                                                    <input id='question_chat' ref={inputRef} type="text" autoComplete="off" placeholder="Search your project for information" className="form-control border border-primary gpt_input" onChange={(e) => handleChangeQuery(e)} />
                                                                    {!searching ? 
                                                                    <button className={`${chatInput.length >= 1 ? `btn btn-primary`: 'btn btn-disabled btn-primary'} border-0`} type="button" onClick={() => handleChatClick()} disabled={chatInput.length < 1}>
                                                                        <FontAwesomeIcon icon={faMagnifyingGlass} fontSize="1rem"></FontAwesomeIcon>
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-primary disabled border-0" type="button" disabled>
                                                                        <Spinner size="sm" color="light" />
                                                                    </button>
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <input id='no-knowledge-data-help' disabled type="text" className="form-control gpt_input" placeholder="Search your project for information" />
                                                                    <button id='no-knowledge-data-help' className="input-group-text">
                                                                        <FontAwesomeIcon icon={faLock} fontSize="1rem"></FontAwesomeIcon>
                                                                    </button>
                                                                    {props.userInfo && props.userInfo.allow_digitise && !props.userInfo.allow_civilsgpt ? (
                                                                        <Tooltip
                                                                            isOpen={tooltipOpen}
                                                                            placement="bottom"
                                                                            target="no-knowledge-data-help"
                                                                            toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                                                                                Instantly search for information across all your PDF project documents - contact Civils.ai to unlock this feature.
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Tooltip
                                                                            isOpen={tooltipOpen}
                                                                            placement="bottom"
                                                                            target="no-knowledge-data-help"
                                                                            toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                                                                                Instantly search all your project documents for information. Upload a construction document to get started.
                                                                        </Tooltip>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>


                                                    </CardBody>
                                                </Card>
                                        </> 

                                    </div>

                                </div>


                                {props.userInfo &&
                                    <Uploader
                                        userInfo = {props.userInfo}
                                        className="mx-auto"
                                        uploadUrl="/geo/record/upload"
                                        updateAgsInfo={(data) => updateAgsInfo(data)}
                                        allDocs={true}
                                        projectId={project.id}
                                        onComplete={() => props.getProjectData(project.id)}
                                        extraDetails={props.extraDetails}
                                        project={project}
                                    />
                                }
                                            
                        </div>
                    ) : (
                        <div className='d-flex justify-content-center flex-column align-items-center'>
                            <img src="assets/build_img.png" alt="Civils.ai" className="img-fluid" />
                            <h2><b>Oops!</b></h2>
                            <h4>{errorMessage}</h4>
                            <button className="btn btn-primary mt-2" onClick={() => navigate('/projects')}>All Projects</button>
                        </div>
                    )}
                    </CardBody>
            </Card>
            { agsInfo.length > 0 && 
                <AgsUploaderVerify
                    agsInfo={agsInfo}
                    toggleModal={()=>onToggleAgsVerifier()}>
                </AgsUploaderVerify>
            }
            <Modal id="updateProject" size="l" isOpen={toggleUpdateProjectLocation} toggle={toggleUpdateProjectLocationModal} centered={true}>
                <NewProjectModal title="Add a location for your project"
                                project={props.project}
                                toggleModal = {()=> toggleUpdateProjectLocationModal()} 
                                getProjectData = {() => props.getProjectData(project.id)}
                                handleChoiceClick = {(project_type) => handleUpdateProjectLocationClick(project_type)}/>
            </Modal>
            <Modal id="projectSharing" size="l" isOpen={sharingOpen} toggle={toggleSharing} centered={true}>
                <ShareProjectModal title="Share your project with others"
                                project={props.project}
                                userInfo={props.userInfo}
                                toggleModal = {()=> toggleSharing()} 
                                getProjectData = {() => props.getProjectData(project.id)}
                                handleChoiceClick = {(project_type) => handleUpdateProjectLocationClick(project_type)}/>
            </Modal>
            <Modal id="searchHistory" size="xl" isOpen={historyOpen} toggle={toggleHistory}>
                <SearcHistory project_id={project.id}
                            handleSearchHistoryClicked={(id)=>props.handleSearchHistoryClick(id)} />
            </Modal>
        </>
    );
}

export default ProjectOverview;