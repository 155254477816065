import { faArrowLeft, faBuilding, faDatabase, faFolder, faCity, faDrawPolygon, faBook, faCloudArrowUp, faMountainSun, faScrewdriverWrench, faEllipsis, faUsers, faList, faGrip, faCocktail, faCoins, faRobot, faArrowUpRightFromSquare, faFolderTree, faBolt, faUserTie, faCaretDown, faListCheck, faPenNib } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import authenticationHandler from '../../utils/authenticationHandler';
import { Modal, ModalBody, ModalHeader, Collapse } from 'reactstrap';
import { useEffect, useState } from 'react';
import httpClientPy from '../../utils/httpClientPy';
import AccountDropdown from '../shared/AccountDropdown';
import { logEvent, setMixpanelID } from '../shared/Mixpanel';
import AgsUploaderVerify from '../map/dataTab/AgsUploaderVerify';
import NewProjectModal from './SideBarComponents/NewProjectModal';
import PricingModal from '../shared/payments/Pricing';


const SideBar = (props) => {

    const navigate = useNavigate();

    const [dropdownOpen, setDropdownOpen] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [agsInfo, setAgsInfo] = useState([]);
    const [digitiseOnly, setDigitiseOnly] = useState(false);
    const [civilsGPT, setCivilsGPT] = useState(false);
    const [toggleNewProjectModal, setToggleNewProjectModal] = useState(false);
    const [modalPricingOpen, setModalPricingOpen] = useState(false);

    const togglePricingModal = () => setModalPricingOpen(!modalPricingOpen);


    useEffect(() => {
        httpClientPy.get(`/user`).then((response) => {
            let userList = response.data
            if (userList.is_enterprise) {
                userList.allow_digitise = userList.enteprise_is_digitiser
                userList.allow_civilsgpt = userList.enterprise_is_civilsgpt
                userList.allow_drawing = userList.enterprise_is_drawing
            } else {
                userList.allow_digitise = userList.subscription_details ?  userList.subscription_details.is_digitise : false
                userList.allow_civilsgpt = userList.subscription_details ? userList.subscription_details.is_civilsgpt : false
                userList.allow_drawing = userList.subscription_details ? userList.subscription_details.is_drawing : false
            }
            setUserInfo(userList)
            setAdmin(userList.enterprise_admin)
            if (props.page === 'detail') {
                props.setUserInfo(userList)
            }
        })
    }, [])


    const handleAddProjectClick = (project_type) => {
        navigate('/map', { state: {project: true, project_type: project_type} });
    }

    const navigateToProjectDetails = (id) => {
        navigate('/project/detail', { state: {id: props.project.id} });
        props.closeChat()
        if (props.page === 'detail') {
            props.setSubpage('overview')
            props.setType(null)
        }
    }

    const navigateToMapWithDetails = () => {
        navigate(`/map`, {state: {project_detail: props.project}});
    }

    const navigateToDesignData = () => {
        logEvent('View Design Data', {"Type": "Design Data"})
        navigate(`/project/detail`, {state: {project: props.project, subpage: 'design', type: 0}});
        // if already on /project/data then call props function
        if (props.page === 'detail') {
            props.setSubpage('design')
            props.setType(0)
        }
    }

    const navigateToCadData = () => {
        logEvent('View CAD Data', {"Type": "CAD Data"})
        navigate(`/project/detail`, {state: {project: props.project, subpage: 'cad', type: 2}});
        // if already on /project/data then call props function
        if (props.page === 'detail') {
            props.setSubpage('cad')
            props.setType(2)
        }
    }

    const navigateToGeotechData = () => {
        logEvent('View Geotech Data', {"Type": "Geotech Data"})
        navigate(`/project/detail`, {state: {project: props.project, subpage: 'geotech', type: 1}});
        if (props.page === 'detail') {
            props.setSubpage('geotech')
            props.setType(1)
        }
    }

    const navigateToRegData = () => {
        logEvent('View Regulations Data', {"Type": "Regulations Data"})
        navigate(`/project/detail`, {state: {project: props.project, subpage: 'reg', type: 3}});
        if (props.page === 'detail') {
            props.setSubpage('reg')
            props.setType(3)
        }
    }

    const navigateToProjects = () => {
        navigate(`/projects`);
    }

    const handleLogout = () => {
        authenticationHandler.logout();
        navigate("/log-in");
      };


    const updateAgsInfo = (data) => {
        setAgsInfo(data);
    };

    const onToggleAgsVerifier = () => {
        setAgsInfo([]);
    };

    const toggleNewProject = () => {
        setToggleNewProjectModal(!toggleNewProjectModal);
    }

    const accountSettings = () => {
    // navigate to external website www.civils.ai/account
    window.open('https://www.civils.ai/account');
    };

    const discordCommunity = () => {
        window.open('https://discord.gg/UFaKqhGNmb');
    };


    const subscriptionSettings = () => {
        // window.open('https://www.civils.ai/create-portal-session') with userInfo.customer as input parameter
        window.open(`https://www.civils.ai/create-portal-session?customer=${userInfo.customer}`);
    };

    const toggleData = () => setDropdownOpen(prevState => !prevState);
    
    return (
        <>
            <Offcanvas show={true} backdrop={false} scroll={true} className='project-sidebar d-none d-md-flex'>
                <Offcanvas.Body className="d-flex flex-column px-2">
                    <div className='logo ps-1'>
                        <img src="assets/logo_white.png" alt="Civils.ai" />
                        <span className="fs-4">Civils.ai</span>
                    </div>
                    <hr className='hr-custom'></hr>

                    {/* <div className="span-text mb-1 ms-2"><span>AI AUTOMATION</span></div> */}

                {props.page === 'list' || props.page === 'umanage' ? (
                    <>
                    <a className={`sub-item mb-1`} onClick={() => toggleNewProject()}>
                        <span><FontAwesomeIcon className="me-2" icon={faDrawPolygon}/></span>
                        <span className="">New project</span>
                    </a>
                    </>
                ) : (
                    <>
                        <a className={`${props.subpage === 'overview' ? 'sub-item-disabled' : 'sub-item'} mb-1`} onClick={() => navigateToProjectDetails()}>
                            <span><FontAwesomeIcon className="me-2" icon={faCity}/></span>
                            <span className="">Project</span>
                        </a>
                        {/* <a className={`${props.subpage === 'overview' ? 'sub-item-disabled' : 'sub-item'} mb-1`} className={`sub-item mb-1`}>
                            <span><FontAwesomeIcon className="me-2" icon={faListCheck}/></span>
                            <span className="">Check</span>
                        </a> */}
                        {/* <a className={`sub-item mb-1`}>
                            <span><FontAwesomeIcon className="me-2" icon={faPenNib}/></span>
                            <span className="">Draft</span>
                        </a> */}
                        

                        <a onClick={() => toggleData()} className="sub-item mb-1 d-flex justify-content-between align-items-center">
                                <span>
                                    <FontAwesomeIcon className="me-2" icon={faFolderTree}/>
                                    <span>Data</span>
                                </span>
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </a>
                            <Collapse isOpen={true}>
                                <div className="px-2 text-light d-flex flex-column">
                                    <a className={`${props.subpage === 'design' ? 'sub-item-disabled' : 'sub-item'} my-0 d-flex justify-content-between`} onClick={() => navigateToDesignData()}>
                                        <small>Reports</small>
                                        {(props.projectExtraDetails && props.projectExtraDetails.ready_non_geotech_data) ? <span className='badge bg-light text-dark ms-auto'>{props.projectExtraDetails.ready_non_geotech_data}</span> : null}
                                    </a>
                                    <a className={`${props.subpage === 'cad' ? 'sub-item-disabled' : 'sub-item'} my-0 d-flex justify-content-between`} onClick={() => navigateToCadData()}>
                                        <small>CAD</small> <span className='badge bg-secondary ms-auto'>Beta</span>
                                        {(props.projectExtraDetails && props.projectExtraDetails.ready_cad_data) ? <span className='badge bg-light text-dark ms-auto'>{props.projectExtraDetails.ready_cad_data}</span> : null}
                                    </a>
                                    <a className={`${props.subpage === 'geotech' ? 'sub-item-disabled' : 'sub-item'} my-0 d-flex justify-content-between`} onClick={() => navigateToGeotechData()}>
                                        <small>Geotech</small>
                                        {(props.projectExtraDetails && props.projectExtraDetails.ready_geotech_data) ? <span className='badge bg-light text-dark ms-auto'>{props.projectExtraDetails.ready_geotech_data}</span> : null}
                                    </a>
                                    <a className={`${props.subpage === 'reg' ? 'sub-item-disabled' : 'sub-item'} my-0 d-flex justify-content-between`} onClick={() => navigateToRegData()}>
                                        <small>Regs</small>
                                        {(props.projectExtraDetails && props.projectExtraDetails.ready_reg_data) ? <span className='badge bg-light text-dark ms-auto'>{props.projectExtraDetails.ready_reg_data}</span> : null}
                                    </a>
                                </div>
                            </Collapse>

                    </>
                )}
                <div className="mt-auto w-100"> {/* This pushes the logout button to the bottom */}
                    <a className={`sub-item mb-1 upgrade-btn ${userInfo && userInfo.subscription && userInfo.is_enterprise === false ? '' : 'd-none'} `} onClick={togglePricingModal}>
                        <span><FontAwesomeIcon className="me-2" icon={faBolt}/></span>
                        <span className="">Upgrade</span>
                    </a>
                    <hr className='hr-custom'></hr>
                    {
                        (props.page === 'detail' || props.page === 'data' || props.page === 'umanage') ? (
                            <a className={`sub-item mb-1`} onClick={() => navigateToProjects()}>
                                <span><FontAwesomeIcon className="me-2" icon={faGrip}/></span>
                                <span className="">My Projects</span>
                            </a>
                        ) : (
                            <a className={`sub-item-disabled mb-1`}>
                                <span><FontAwesomeIcon className="me-2" icon={faGrip}/></span>
                                <span className="">My Projects</span>
                            </a>
                        )
                    }
                    <AccountDropdown/>
                </div>
            </Offcanvas.Body>
            </Offcanvas>
        
        { agsInfo.length > 0 && 
            <AgsUploaderVerify
                agsInfo={agsInfo}
                toggleModal={()=>onToggleAgsVerifier()}>
            </AgsUploaderVerify>
        }
        <Modal id="newProject" size="l" isOpen={toggleNewProjectModal} toggle={toggleNewProject} centered={true}>
            <NewProjectModal title="Your new project is starting..."
                            toggleModal = {()=> toggleNewProject()} 
                            handleChoiceClick = {(project_type) => handleAddProjectClick(project_type)}/>
        </Modal>
        {userInfo &&
        <PricingModal 
            isOpen={modalPricingOpen} 
            toggle={togglePricingModal} 
            title="Upgrade now to get more access for your team."
            userInfo={userInfo}
        >
        </PricingModal>
        }
        </>
    )
}

export default SideBar;