import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody, FormGroup, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonDigging, faPlug } from "@fortawesome/free-solid-svg-icons";
import SearchUploader from "./uploaders/SearchUploader";
import GeotechUploader from "./uploaders/GeotechUploader";
import DraftReportUploader from "./uploaders/DraftReportUploader";

const Uploader = (props) => {
  // Initialize uploadType from localStorage or default to 0 or 1 based on userInfo
  const initialUploadType = null
  
  const [uploadType, setUploadType] = useState(initialUploadType);
  const navigate = useNavigate();

  const navigateToProjectData = (type) => {
    navigate(`/project/data`, {state: {project: props.project, type: type}});
  }

  useEffect(() => {
    // Store the uploadType in localStorage whenever it changes
    localStorage.setItem('uploadType', uploadType);
  }, [uploadType]);

  const handleSelectChange = (e) => {
    let selectedId = e.target.selectedOptions[0].value;
    if (selectedId !== null) {
      selectedId = parseInt(selectedId, 10);
    }
    setUploadType(selectedId);
  };

  return (
    <>
      <Card className="w-100 mt-4 shadow-sm d-flex flex-grow-1 flex-column">
        <CardHeader className="bg-light px-2 automationbutton">
          {/* Buttons to select upload type */}      
            <select 
              class="form-select" 
              onChange={handleSelectChange}
              style={{ width: 'auto', minWidth: '200px' }} // Adjust minWidth as needed
              aria-label="Default select example">
              <option selected value={null}>Autodetect type</option>
              <option value="0">Project report</option>
              <option value="2">CAD Drawing</option>
              <option value="1">Geotech report</option>
              <option value="3">Regulation / Building code</option>
            </select>  
        </CardHeader>
        <CardBody className={`show bgworkflow overflow-auto`} id="automation1">
          {uploadType === 0 ? <SearchUploader {...props} projectId={props.project.id} mode={0} />
            : uploadType === 1 ? <GeotechUploader {...props} projectId={props.project.id} />
            : uploadType === 2 ? <SearchUploader {...props} projectId={props.project.id} mode={2} />
            : uploadType === 3 ? <SearchUploader {...props} projectId={props.project.id} mode={3} />
            : <SearchUploader {...props} projectId={props.project.id} mode={null} />
          }
        </CardBody>
      </Card>
    </>
  );
};

export default Uploader;
