import { useEffect, useState } from "react";


import { Spinner } from "reactstrap";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faCheck, faLocationCrosshairs, faFilePdf, faDatabase, faLayerGroup, faPlus, faPersonDigging, faFilter, faDownload, faCircleInfo, faArrowsRotate, faWandMagicSparkles} from "@fortawesome/free-solid-svg-icons";



library.add(faCopy, faCheck, faDownload, faPersonDigging);

const ProgressCounter = (props) => {
  //count is used for a timer which runs on 'pending' records to give users some feedback that their record is being processed
  const [count, setCount] = useState(0);

  var limit = 60
  var progressIncrement = (1/(limit*60))*100

  useEffect(() => {
    const id = setInterval(() => {
      setCount((oldCount) => oldCount + progressIncrement);
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  if(props.status.toLowerCase() === "pending" || props.status.toLowerCase() === "pre-pending" || props.status.toLowerCase() === "post-pending"){
    // create new date with UTC timezone
    let dateTimeNow = new Date();
    var requestedDate = new Date(props.requestedDate);
    if ((dateTimeNow-requestedDate) < 0){

    }
    let minutesElapsed = (dateTimeNow-requestedDate)/(1000*60)
        
    var progress = Math.abs(Math.round(((minutesElapsed/limit)*100)*100)/100)
    var progress_holder = String(progress)+'%'
    if (progress>100){
      progress = 100
      progress_holder=" Sent for check"
    }
  }

  return (
    <>
    {props.status.toLowerCase() === "pending" || props.status.toLowerCase() === "pre-pending" || props.status.toLowerCase() === "post-pending" ? 
    <span className={`badge rounded-pill yellow `}>
      <div className="spinner-border spinner-border-sm" style={{height:'0.6rem', width:'0.6rem'}} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <span className="ms-1">{progress_holder}</span>
    </span>
  : props.status.toLowerCase() === "ready" ? 
    <span className={`badge rounded-pill green `}>
      Ready
    </span>
  : <span className={`badge rounded-pill red `}>
      Failed
    </span>
  }
  </>
  );
};

export default ProgressCounter;
