import React, { useState,useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Progress, Card, CardBody, CardTitle, CardFooter, CardText, CardSubtitle, CardHeader } from 'reactstrap';
import authenticationHandler from '../../utils/authenticationHandler';

const LoadingCivils = (props) => { 
    const [progress, setProgress] = useState(0);
    const [modalDisplay, setModalDisplay] = useState(true);

    const toggleModalDisplay = () => {
        setModalDisplay(!modalDisplay);
        props.toggleModal();
    }

    const bookMeeting = () => {
        window.location.href = 'https://calendar.app.google/bZNEQSnjK13MLnEa6';
    }
    
    const backHome = () => {
        authenticationHandler.logout();
        window.location.href = 'https://civils.ai/';
    }

    return(
    props.userInfo && (props.userInfo.subscription.subscription_id===1 && !props.userInfo.is_enterprise) ?
    <>
        <Modal isOpen={true} className='pricingModal' centered size='lg'>
            <ModalHeader className='title-bold border-0' toggle={props.toggle}>
                <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                    Please contact us for access to Civils.ai
                </h4>
            </ModalHeader>
            <ModalBody>
                <div className="text-start">
                    <div>Get access by contacting us at info@civils.ai</div>
                </div>
            </ModalBody>
            <ModalFooter className="border-0 pt-0">
                <div className="w-100">
                    <button className="btn btn-outline-dark" onClick={()=>backHome()}>Back to homepage</button>
                </div>
            </ModalFooter>
        </Modal>
    </>
    :
    <Modal isOpen={modalDisplay} className='pricingModal' centered toggle={toggleModalDisplay}>
        <ModalHeader className='title-bold border-0' toggle={props.toggle}>
            <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                👋 Welcome to Civils.ai
            </h4>
        </ModalHeader>
        <ModalBody>
            <div className="text-center">
                <video className="mx-auto shadow rounded mb-2 w-100" loading="lazy" autoPlay={true} muted={true} loop={true}>
                    <source src="https://d122f4822rob2u.cloudfront.net/upload2.mp4" type="video/mp4" />
                </video>
                <div>Drag and drop your PDF's to extract your project information and start automating your project.</div>
            </div>
        </ModalBody>
        <ModalFooter className="border-0 pt-0">
            <div className="text-center w-100">
                <button className="btn btn-primary btn-lg" onClick={()=>toggleModalDisplay()}>Start my first project</button>
            </div>
        </ModalFooter>
      </Modal>
    

    )

 }; export default LoadingCivils;