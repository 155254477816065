import { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import httpClientPy from '../../utils/httpClientPy';
import SideBar from './SideBar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faDrawPolygon, faEllipsis, faLock } from "@fortawesome/free-solid-svg-icons";
import Loader from '../shared/Loader';
import ProjectDropdown from '../shared/ProjectDropdown';
import LoadingCivils from '../shared/LoadingCivils';
import authenticationHandler from '../../utils/authenticationHandler';
import NewProjectModal from './SideBarComponents/NewProjectModal';
import { faUsers } from '@fortawesome/fontawesome-free-solid';

const ProjectsList = (props) => {

    const navigate = useNavigate();

    // variable to handle if the api has returned error
    const [error, setError] = useState(false);
    // variable to store the error message
    const [errorMessage, setErrorMessage] = useState(null);
    // variable to store the recent project
    const [recentProject, setRecentProject] = useState(null);
    // variable to store all the projects
    const [allProjects, setAllProjects] = useState(null);
    // variable to handle loading
    const [loading, setLoading] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);

    const [toggleNewProjectModal, setToggleNewProjectModal] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        // call api to get all projects
        setError(false);
        setErrorMessage(null);
        refreshProjects();
        getUser();
    }, []);

    const toggleNewProject = () => {
        setToggleNewProjectModal(!toggleNewProjectModal);
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const handleLogout = () => {
        authenticationHandler.logout();
        navigate("/log-in");
      };


    const handleAddProjectClick = (project_type) => {
        navigate('/map', { state: {project: true, project_type: project_type} });
    }

    const refreshProjects = () => {
        httpClientPy.get('/project/index')
            .then((response) => {
                setRecentProject(response.data.project_recent);
                let allProjects_unsorted = response.data.all_project;
                
                // reverse the array to get the latest project first
                allProjects_unsorted.reverse();

                let sortedProjects = allProjects_unsorted.slice().sort((a, b) => {
                    const dateA = new Date(a.last_updated_datetime);
                    const dateB = new Date(b.last_updated_datetime);
                    return dateB - dateA; // Compare in descending order (newest first)
                  });


                setAllProjects(sortedProjects);
                setLoading(false);
            })
            .catch((error) => {
                // console.log(error);
                setError(true);
                setErrorMessage(error.response.data.detail);
            })
    }


    const getUser = () => {
        httpClientPy.get(`/user`).then((response) => {
            let userList = response.data
            if (userList.is_enterprise) {
                userList.allow_digitise = userList.enteprise_is_digitiser
                userList.allow_civilsgpt = userList.enterprise_is_civilsgpt
            } else {
                userList.allow_digitise = userList.subscription_details ?  userList.subscription_details.is_digitise : false
                userList.allow_civilsgpt = userList.subscription_details ? userList.subscription_details.is_civilsgpt : false
            }
            setUserInfo(userList)
            setLoadingUser(false)
        })
    }


    const handleViewProjectClick = (id) => {
        navigate('/project/detail', { state: {id: id, type:null, subpage: 'overview'}, });
    }
    
    return (
        <div className="d-flex">
            <div id="sidebarParent" className=' d-none d-md-block' style={{minWidth:'190px'}}>
                <SideBar page={'list'} loading={loading}/>
            </div> 
            {!loading && !loadingUser ? (
                <>  
                        <div  className="flex-fill px-3 pe-lg-0 py-4 row" style={{maxWidth:'100vw'}}>
                            {!error && recentProject !== null ? (
                            <>
                                {recentProject.length !== 0 ? (
                                <>
                                <div>
                                    <div className='container mt-2' >
                                        {loading ? (
                                        <Loader loading={loading} />
                                        ) : (
                                            <>
                                                {/* <div className="d-block d-lg-none">
                                                    <b>Civils.ai is optimised for the desktop</b>. Please sign in on a desktop to create a new project. Or access our free design tools <a href='https://civils.ai/free-construction-calculators'>here</a>.
                                                </div> */}
                                                <h3 className='title-bold'>My Projects</h3>
                                                <div id="project_list" className='row'> 
                                                    {allProjects.map((card, index) => (
                                                        <div className='col-sm-6 col-md-3 col-lg-3 pt-4 d-flex' key={index}>
                                                            <Card
                                                                className='project-list-card rounded flex-grow-1 d-flex flex-column overflow-hidden'
                                                                onClick={() => handleViewProjectClick(card.id)}
                                                                style={{ minHeight: '100%' }}
                                                            >
                                                                <CardBody
                                                                    className="project-list-card-img-background"
                                                                    style={card.project_type !== 2 ? { backgroundImage: `url(${card.image_url})` } : {}}
                                                                >
                                                                    {/* Overlay for text readability */}
                                                                    <div className="project-list-card-content">
                                                                        <CardTitle tag="h5">
                                                                            <span style={{ maxWidth: '100%' }} className="d-inline-block text-truncate">{card.name}</span>
                                                                        </CardTitle>

                                                                        <CardBody className="mt-2 p-0">
                                                                            <div>
                                                                                <small><FontAwesomeIcon icon={faClock}></FontAwesomeIcon></small>
                                                                                <small>&nbsp; Last updated:&nbsp; {card.last_updated_datetime}</small>
                                                                            </div>
                                                                            {card.shared &&
                                                                                <div>
                                                                                    <small><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon></small>
                                                                                    <small>&nbsp; Team project</small>
                                                                                </div>
                                                                            }

                                                                        </CardBody>
                                                                    </div>
                                                                    <div className="project-list-card-button position-absolute bottom-0 end-0 p-3">
                                                                        {(card.shared && userInfo.george_id !== card.owner_id) ? 
                                                                            <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                                                            :
                                                                            <ProjectDropdown project_id={card.id} refreshProjects={()=>refreshProjects()} shared={true} />
                                                                        }
                                                                    </div>
                                                                </CardBody>
                                                            </Card>

                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='d-block d-sm-none'>
                                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                    <DropdownToggle className="btn btn-light w-100 mt-3" tag="div">
                                                        <span><FontAwesomeIcon className="me-2" icon={faEllipsis}/></span>
                                                        <span>Profile</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={handleLogout}>Sign out</DropdownItem>
                                                    </DropdownMenu>
                                                    </Dropdown>
                                                </div>

                                            </>
                                        )}
                                    </div>
                                </div>
                                </>
                                ) : (
                                <>
                                    {/* <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                                        <h3><b>👋 Welcome to Civils.ai</b></h3>
                                        <a className="btn btn-primary mt-3" onClick={() => toggleNewProject()}>
                                            Start a new project
                                        </a>
                                    </div> */}

                                    <Modal id="newProject" size="l" isOpen={toggleNewProjectModal} toggle={toggleNewProject} centered={true}>
                                        <NewProjectModal title="Your new project is starting..."
                                                        toggleModal = {()=> toggleNewProject()} 
                                                        handleChoiceClick = {(project_type) => handleAddProjectClick(project_type)}/>
                                    </Modal>
                                </>
                                )}
                            </>
                            ) : (
                            <>
                                <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                                    <img src="assets/build_img.png" alt="Civils.ai" />
                                    <h2><b>Oops!</b></h2>
                                    <h4>{errorMessage}</h4>
                                </div>
                            </>
                            )}
                        </div>

                    {(allProjects && allProjects.length===0) &&
                        <>
                            <LoadingCivils 
                            userInfo={userInfo}
                            loading = {loading}
                            toggleModal = {()=> toggleNewProject()}
                            >
                            </LoadingCivils>
                        </>
                     }

                </>



            ) : (
                <>
                    <div className="flex-fill px-3 pe-lg-0 py-4 row text-center d-flex justify-content-center align-items-center" style={{ maxWidth: '100vw', height: '70vh' }}>
                        <span>
                            <span
                                className="spinner-border spinner-border-md mx-auto"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                        </span>
                    </div>
                </>
            )}
            </div>
    )
    
}

export default ProjectsList;