import { Outlet } from "react-router-dom";
import React, { Component } from "react";
import Header from "../Header";

export class CoverLayout extends Component {
  render() {
    return (
      <div>
        <Header />
        <main className="cover-layout text-center w-100">
          <Outlet />
        </main>
      </div>
    );
  }
}
